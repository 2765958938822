<div class="text-right close-button-div">
    <button mat-dialog-close class="close-button"></button>
</div>
<div class="wrap">
    <div [ngClass]="{call: startRecording, recordings: showRecordings}">
        <p *ngIf="startRecording" class="call_from" [innerHTML]="'CALL.RECORDING.START' | translate"></p>
        <div *ngIf="showRecordings">
            <p class="download-title">{{'CALL.FINISHED.RECORDINGS' | translate}}</p>
            <div *ngIf="loading" class="recording-loader">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                <div>{{'CALL.FINISHED.RECORDINGS_LOADER' | translate}}</div>
            </div>
            <p *ngIf="!loading && recordings.length === 0" [innerHTML]="'EXPERT.APPOINTMENTS.RECORDINGS_STATUS' | translate"></p>
            <div *ngIf="!loading && recordings.length > 0" class="links">
                <div *ngFor="let recording of recordings; let i = index;" class="link">
                    <a *ngIf="recording.url" id="recording_{{i}}" class="btn btn-recording" href="{{recording.url}}" target="_blank" rel="noopener noreferrer">
                        {{'CALL.RECORDING.TITLE' | translate}} {{i + 1}}
                    </a>
                </div>
            </div>
        </div>
        <p *ngIf="recordingError" [innerHTML]="'EXPERT.APPOINTMENTS.RECORDINGS_ERROR' | translate"></p>
        <app-form-button
            (click)="close()">
            {{'CALL.RECORDING.OK' | translate}}
        </app-form-button>
    </div>
</div>