import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { ExpertAuthModule } from './expert/auth/module';
import { WarningUpgradeModule } from './expert/dashboard/package/warning-upgrade/module';
import { RequestUpgradeModule } from './expert/dashboard/package/request-upgrade/module';
import { FormModule } from './form/form.module';
import { CallModule } from './expert/call/call.module';
import { DialogConfirmModule } from './shared/dialog-confirm/module';
import { ExpertComponent } from './expert/component';
import { NavigationComponent } from './expert/navigation/component';
import { ExpertDashboardModule } from './expert/dashboard/module';
import { ApiService } from './shared/api.service';
import { AuthService } from './shared/auth.service';
import { AuthGuard } from './shared/auth-guard.service';
import { SuperAdminGuard } from './shared/super-admin-guard.service';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CacheMechanism, LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { Location } from '@angular/common';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { EventService } from './shared/event.service';
import { LoadingService } from './shared/loading.service';
import { DialogHelper } from './shared/dialog.helper';
import { AppointmentsService } from './expert/dashboard/appointments/service';
import { ErrorService } from './shared/error.service';
import { CanDeactivateGuard } from './shared/can-deactivate/can-deactivate.guard';
import { WhiteLabelService } from './shared/white-label.service';
import { SubscriptionService } from './shared/subscription.service';
import { ValidationService } from './shared/validation.service';
import { UpgradeService } from './shared/upgrade.service';
import { OnboardingService } from './shared/onboarding.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatRadioDefaultOptions, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { HammerConfig } from './shared/hammer-config';
import { MatMenuModule } from '@angular/material/menu';
import {
    HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import 'hammerjs';

export function defaultLanguage(languages, cachedLang, browserLang) {
    return cachedLang ? cachedLang : (languages.includes(browserLang) ? browserLang : 'en');
}

const routes: Routes = [
    {
        path: '',
        redirectTo: 'expert/appointments',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '/404',
        pathMatch: 'full',
    },
    {
        path: '404',
        loadChildren: () => import('./not-found/module').then(m => m.NotFoundModule),
    },
];

const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};
const radioStyle: MatRadioDefaultOptions = {
    color: 'primary'
};

@NgModule({
    declarations: [
        AppComponent,
        ExpertComponent,
        NavigationComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, 'assets/locales/', '.json?nocache=' + (new Date()).getTime());
                },
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: (translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) => {
                    return new LocalizeRouterHttpLoader(translate, location, settings, http);
                },
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            },
            cacheMechanism: CacheMechanism.Cookie,
            defaultLangFunction: defaultLanguage,
        }),
        SimpleNotificationsModule.forRoot(),
        BrowserAnimationsModule,
        FormModule,
        MatIconModule,
        MatProgressBarModule,
        MatDialogModule,
        CallModule,
        DialogConfirmModule,
        ExpertAuthModule,
        ExpertDashboardModule,
        HammerModule,
        MatButtonModule,
        MatMenuModule,
        WarningUpgradeModule,
        RequestUpgradeModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: AuthService) => {
                return (): Promise<any> => authService.initializer();
            },
            multi: true,
            deps: [AuthService],
        },
        {
            provide: ErrorHandler,
            useClass: ErrorService,
        },
        ApiService,
        AuthService,
        AuthGuard,
        SuperAdminGuard,
        CanDeactivateGuard,
        EventService,
        LoadingService,
        AppointmentsService,
        DialogHelper,
        ErrorService,
        WhiteLabelService,
        SubscriptionService,
        ValidationService,
        UpgradeService,
        OnboardingService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig ,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: appearance
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: radioStyle,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
