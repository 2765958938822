import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-iframe',
    template: `
        <div class="text-right close-button-div">
            <button mat-dialog-close class="close-button popup-no-padding"></button>
        </div>
        <iframe [src]="url" class="full"></iframe>
    `,
    styleUrls: ['./component.css'],
})
export class FormiFrameDialogComponent {
    url: SafeResourceUrl;

    constructor(public dialogRef: MatDialogRef<FormiFrameDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
    }
}
