<div class="text-right close-button-div">
    <button mat-dialog-close class="close-button"></button>
</div>
<h2 class="text-center mt-0"
    [innerHTML]="'REGISTER.PACKAGES.WARNING_UPDATE.TITLE' | translate"></h2>
<p class="px-2 text-center"
    [innerHTML]="description">
</p>
<div class="d-flex justify-content-center">
    <app-form-button
        [type]="'button'"
        (o_click)="redirectToSubscriptionPage()">
        {{'REGISTER.PACKAGES.UPGRADE_PACKAGE' | translate}}
    </app-form-button>
</div>
