import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
/* Assigning specific keys to scripts */
/* Try/catch needed as it can cause crashes due to Brave Browser tracking script policy */
try {
    /* Intercom */
    (<any>window).intercomSettings.app_id = environment.intercomId;
    /* Google analytics */
    // (<any>window).gtag('config', environment.googleAnalyticsKey);
    /* Segment */
    (<any>window).analytics.load(environment.segmentKey);

    /* Google analytics */
    // const script1 = document.createElement('script');
    // script1.async = true;
    // script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
    // document.head.appendChild(script1);

    /* Google Tag Manager */
    // const script2 = document.createElement('script');
    // script2.innerHTML = `
    //     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //     'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=` + environment.googleTagManagerAuth +
    //     `&gtm_preview=` + environment.googleTagManagerEnv + `&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    //     })(window,document,'script','dataLayer','GTM-5TVRKZ3');
    // `;
    // document.head.appendChild(script2);
    /* Google Tag Manager */
    // const noscript = document.createElement('noscript');
    // noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5TVRKZ3&gtm_auth=` + environment.googleTagManagerAuth +
    // `&gtm_preview=` + environment.googleTagManagerEnv + `&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    // document.body.appendChild(noscript);
} catch (err) {
    console.error(err);
}
