import { NgModule } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '../../form/form.module';
import { FormButtonModule } from '../../form/button/module';
import { ChargeCallPriceComponent } from './charge-guest-modal/charge-call-price.component';
import { CallSatrtRecordingComponent } from './recording/call-start-recording.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        TranslateModule,
        FormModule,
        FormButtonModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    declarations: [
        ChargeCallPriceComponent,
        CallSatrtRecordingComponent,
    ],
    exports: [
        ChargeCallPriceComponent,
        CallSatrtRecordingComponent,
    ],
    providers: [
        CurrencyPipe,
    ],
})
export class CallModule {
}
