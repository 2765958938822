import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../shared/event.service';
import { AuthService } from '../../../shared/auth.service';
import * as moment from 'moment-timezone';
import { Subject ,  Observable, Subscription } from 'rxjs';
import { OnboardingService } from '../../../shared/onboarding.service';

@Injectable()
export class AppointmentsService {
    /* Global variables */
    meetingsPerPage = 15; /* Global amount of meetings per page of the paginator. */
    /* End of global variables */

    appointments = {
        pending: [],
        upcoming: [],
        past: null,
        canceled: null,
        pending_reschedule: [],
    };
    pagination = {
        pending: {
            from: 0,
            to: 0,
            total: 0,
            disablePrevNext: false,
        },
        upcoming: {
            from: 0,
            to: 0,
            total: 0,
            disablePrevNext: false,
        },
        past: {
            from: 0,
            to: 0,
            total: 0,
            disablePrevNext: false,
        },
        canceled: {
            from: 0,
            to: 0,
            total: 0,
            disablePrevNext: false,
        },
        pending_reschedule: {
            from: 0,
            to: 0,
            total: 0,
            disablePrevNext: false,
        },
    };
    changed_pending_source: Subject<any> = new Subject<any>();
    changed_upcoming_source: Subject<any> = new Subject<any>();
    changed_past_source: Subject<any> = new Subject<any>();
    changed_canceled_source: Subject<any> = new Subject<any>();
    changed_pending_reschedule_source: Subject<any> = new Subject<any>();
    get_meetings_source: Subject<any> = new Subject<any>();

    changed_pending: Observable<any> = this.changed_pending_source.asObservable();
    changed_upcoming: Observable<any> = this.changed_upcoming_source.asObservable();
    changed_past: Observable<any> = this.changed_past_source.asObservable();
    changed_canceled: Observable<any> = this.changed_canceled_source.asObservable();
    changed_pending_reschedule: Observable<any> = this.changed_pending_reschedule_source.asObservable();
    get_meetings: Observable<any> = this.get_meetings_source.asObservable();

    subscriptionOEvent: Subscription = null;
    subscriptionOCallFinished: Subscription = null;

    constructor(private apiService: ApiService,
                private authService: AuthService,
                private translate: TranslateService,
                private eventService: EventService,
                private onboardingService: OnboardingService) {
        if (!this.subscriptionOEvent) {
            this.subscriptionOEvent = eventService.oEvent.subscribe((data: any) => {
                if (['appointment_pending', 'appointment_status_finished', 'appointment_status_canceled',
                    'appointment_rescheduled_request', 'appointment_rescheduled_confirmed'].indexOf(data['type']['key']) >= 0) {
                    this.get_meetings_source.next();

                    this.onboardingService.stepCompleted('scheduled_meeting');

                    if (data['type']['key'] === 'appointment_pending') {
                        /* Only need to get upcoming and pending meetings
                            as this is triggered when expert or guest books a meeting */
                        this.getMeetings('upcoming', false, true);
                        this.getMeetings('pending', false, true);
                    }
                    if (data['type']['key'] === 'appointment_status_finished') {
                        /* Only need to get upcoming and past meetings
                            as this is triggered when expert ends the call */
                        this.getMeetings('past', false, true);
                        this.getMeetings('upcoming', false, true);
                    }
                    if (data['type']['key'] === 'appointment_status_canceled') {
                        /* Need to get all meetings beside past meetings
                            as past => cancelled does not exist while the others exist */
                        this.getMeetings('canceled', false, true);
                        this.getMeetings('pending', false, true);
                        this.getMeetings('pending_reschedule', false, true);
                        this.getMeetings('upcoming', false, true);
                    }
                    if (data['type']['key'] === 'appointment_rescheduled_request') {
                        /* Need to get all pending_reschedule, upcoming, and pending meetings
                            as you can reschedule from these 2 statuses */
                        this.getMeetings('pending_reschedule', false, true);
                        this.getMeetings('upcoming', false, true);
                        this.getMeetings('pending', false, true);
                    }
                    if (data['type']['key'] === 'appointment_rescheduled_confirmed') {
                        /* Need to get all pending_reschedule, upcoming, and pending meetings
                            as you can reschedule from these 2 statuses */
                        this.getMeetings('pending_reschedule', false, true);
                        this.getMeetings('upcoming', false, true);
                        this.getMeetings('pending', false, true);
                    }
                }
            });
        }
        if (!this.subscriptionOCallFinished) {
            this.subscriptionOCallFinished = eventService.oCallFinished.subscribe(() => {
                /* Only need to get upcoming and past meetings
                    as this is triggered when expert ends the call */
                this.getMeetings('past', false, true);
                this.getMeetings('upcoming', false, true);
            });
        }
    }

    /**
     * [view]: the number/id of the view
     * If the view is the list view, we need to reget all meetings as we ignore what actions the expert took
    */
    switchView(view: number) {
        if (view === 0) {
            this.getMeetings('upcoming', false, true);
            this.getMeetings('pending', false, true);
            this.getMeetings('pending_reschedule', false, true);
            this.getMeetings('past', false, true);
            this.getMeetings('canceled', false, true);
        }
    }
    /**
     * [appointments]: the list of appointments to set
     * [type]: type of the meeting (pending, pending_reschedule, upcoming, past, canceled)
     * [currentPage]: whether you want the current page to be loaded or not.
     *
     */
    setMeetings(appointments: any, type: string, currentPage: boolean) {
        const listAppointments = appointments.appointments;
        for (let i = 0; i < listAppointments.length; i++) {
            listAppointments[i]['start'] = moment(listAppointments[i]['start']).format();
            listAppointments[i]['start'] = listAppointments[i]['start'].replace(/\s/g, 'T'); // Safari fix
            listAppointments[i]['end'] = moment(listAppointments[i]['start']).add(listAppointments[i]['length_requested'], 'm').format();
            listAppointments[i]['end'] = listAppointments[i]['end'].replace(/\s/g, 'T'); // Safari fix
        }
        this.appointments[type] = listAppointments;

        this.pagination[type].from = appointments.from;
        this.pagination[type].to = appointments.to;
        this.pagination[type].total = appointments.total;
        /* If the current page was reloaded, then next and previous page should become null
            as some meetings could be present on both current page and old previous/next page */
        this['changed_' + type + '_source'].next({ reset_next_prev_page: currentPage });
    }

    /**
     * [type]: type of the meeting (pending, pending_reschedule, upcoming, past, canceled)
     *
     * [next]: Whether you want the next page to be loaded or instead the previous page.
     *
     * [currentPage]: whether you want the current page to be loaded or not.
     * It will take priority over the next param.
    */
    getMeetings(type: string, next: boolean, currentPage: boolean) {
        let from = 1;
        /* from cannot be lower than 1 */
        if (currentPage) {
            from = this.pagination[type].from > 0 ? this.pagination[type].from : 1;
        } else {
            if (next) {
                from = this.pagination[type].to + 1;
            } else if (this.pagination[type].from - this.meetingsPerPage > 0) {
                from = this.pagination[type].from - this.meetingsPerPage;
            }
        }
        /* Forbid user to change pages while we are already getting one */
        this.pagination[type].disablePrevNext = true;
        this.apiService.request({
            method: 'get',
            btnIm: true,
            url: '/appointments/page/',
            params: {
                from: from,
                amount: this.meetingsPerPage,
                type: type,
            },
            sessionHash: true,
        }).subscribe((response: any) => {
            this.setMeetings(response, type, currentPage);
        }, (error) => {
            console.error(error);
            this.pagination[type].disablePrevNext = false;
        });
    }

    reset() {
        this.appointments = {
            pending: [],
            upcoming: [],
            past: null,
            canceled: null,
            pending_reschedule: [],
        };
        this.pagination = {
            pending: {
                from: 0,
                to: 0,
                total: 0,
                disablePrevNext: false,
            },
            upcoming: {
                from: 0,
                to: 0,
                total: 0,
                disablePrevNext: false,
            },
            past: {
                from: 0,
                to: 0,
                total: 0,
                disablePrevNext: false,
            },
            canceled: {
                from: 0,
                to: 0,
                total: 0,
                disablePrevNext: false,
            },
            pending_reschedule: {
                from: 0,
                to: 0,
                total: 0,
                disablePrevNext: false,
            },
        };
    }

    /**
     * [appointmentId]: the meeting/appointment id
     * [status]: the new status we want for the meeting
     * [type]: type of the meeting (pending, pending_reschedule, upcoming, past, canceled)
    */
    async updateStatus(appointmentId, status, reason, type) {
        for (const i in this.appointments[type]) {
            if (this.appointments[type].hasOwnProperty(i)) {
                if (this.appointments[type][i]['id'] === appointmentId) {
                    await new Promise((resolve, reject) => {
                        this.apiService.request({
                            method: 'put',
                            btnIm: true,
                            url: '/appointments/',
                            params: {
                                appointment_id: appointmentId,
                                status: status >= 20 ? 'cancel' : 'approve',
                                reason: reason,
                            },
                            sessionHash: true,
                        }).subscribe(() => {
                            this.getMeetings(status >= 20 ? 'canceled' : 'upcoming', false, true);
                            this.getMeetings(type, false, true);
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.DESC'),
                                'success'
                            );
                        }, (error) => {
                            console.error(error);
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.DESC'),
                                'warn'
                            );
                        });
                    });
                    break;
                }
            }
        }
    }

    /* Will only work if accepting the reschedule of a pending_reschedule meeting.
        Should stay this way logically. */
    async acceptReschedule(appointmentId) {
        for (const i in this.appointments.pending_reschedule) {
            if (this.appointments.pending_reschedule.hasOwnProperty(i)) {
                if (this.appointments.pending_reschedule[i]['id'] === appointmentId) {
                    await new Promise((resolve, reject) => {
                        this.apiService.request({
                            method: 'put',
                            btnIm: true,
                            url: '/reschedule/accept/',
                            params: {
                                appointment_id: appointmentId,
                            },
                            sessionHash: true,
                        }).subscribe(() => {
                            /* Need to reget all the upcoming and pending_reschedule meetings
                                as this is run when the user do pending_reschedule => upcoming */
                            this.getMeetings('upcoming', false, true);
                            this.getMeetings('pending_reschedule', false, true);
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.DESC'),
                                'success'
                            );
                        }, (error) => {
                            console.error(error);
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.DESC'),
                                'warn'
                            );
                        });
                    });
                    break;
                }
            }
        }
    }

    /* Will only work if updating the recording status of an upcoming/confirmed meeting.
        Should stay this way logically. */
    async updateRecordingStatus(appointmentId, is_recorded) {
        for (const i in this.appointments.upcoming) {
            if (this.appointments.upcoming.hasOwnProperty(i)) {
                if (this.appointments.upcoming[i]['id'] === appointmentId) {
                    await new Promise((resolve, reject) => {
                        this.apiService.request({
                            method: 'put',
                            btnIm: true,
                            url: '/appointments/is_recorded/',
                            params: {
                                appointment_id: appointmentId,
                                is_recorded: is_recorded,
                            },
                            sessionHash: true,
                        }).subscribe(() => {
                            this.appointments.upcoming[i]['is_recorded'] = is_recorded;
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.DESC'),
                                'success'
                            );
                        }, (error) => {
                            this.getMeetings('upcoming', false, true);
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.DESC'),
                                'warn'
                            );
                        });
                    });
                    break;
                }
            }
        }
    }

    /* archive past or canceled meeting */
    async archive(appointmentId, from) {
        try {
            const result = await this.apiService.request({
                method: 'put',
                btnIm: true,
                url: '/appointments/archive',
                params: {
                    appointment_id: appointmentId,
                },
                sessionHash: true,
            }).toPromise();
            if (result) {
                if (from) {
                    this.getMeetings(from, false, true);
                }
                this.eventService.print(
                    this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.TITLE'),
                    this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.DESC'),
                    'success'
                );
            }
        } catch (err) {
            console.error(err);
            this.eventService.print(
                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.TITLE'),
                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.DESC'),
                'warn'
        );
        }
    }
}
