import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { CurrencyPipe } from '@angular/common';
import { LoadingService } from '../../../shared/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../shared/event.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../shared/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-charge-call-price',
    templateUrl: './charge-call-price.component.html',
})

export class ChargeCallPriceComponent extends OnDestroyMixin implements OnInit {
    length = 0;
    priceMax = 0;
    appointment;
    priceMaxFormatted;
    chosenPrice;
    loadingKey = 'authCallPriceSubmit';
    disableSubmit = false;
    user: any = null;

    constructor(public dialogRef: MatDialogRef<ChargeCallPriceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private apiService: ApiService,
        private authService: AuthService,
        private localize: LocalizeRouterService,
        private currencyPipe: CurrencyPipe,
        private loadingService: LoadingService,
        private translate: TranslateService,
        private eventService: EventService,
        private notificationsService: NotificationsService) {
        super();

        this.user = this.authService.user;
        if (typeof data === 'object') {
            this.appointment = data;
        }
        if (Object.keys(data).length) {
            this.length = data.length;
        }
        loadingService.change.pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
            this.disableSubmit = this.loadingService.isLoading(this.loadingKey);
        });
    }

    async ngOnInit() {
        const prices = await this.apiService.request({
            method: 'get',
            btnIm: true,
            url: '/appointments/price/',
            params: {
                appointment_id: this.appointment['id'],
            },
            sessionHash: true,
        }).toPromise();
        this.priceMax = prices['maxCharged'];
        this.length = Math.floor(this.length / 60);
        this.chosenPrice = this.priceMax;
        this.priceMaxFormatted = this.currencyPipe.transform(this.priceMax, this.appointment['currency_code'].toUpperCase(), 'symbol');
    }

    async submit() {
        const u = this.user;
        if (this.loadingService.isLoading(this.loadingKey) === false && this.chosenPrice > 0 && this.chosenPrice <= this.priceMax) {
            if (u.billing_city.length && u.billing_address && u.billing_zip) {
                this.loadingService.add(this.loadingKey);
                try {
                    await this.apiService.request({
                        method: 'post',
                        btnIm: true,
                        url: '/appointments/price/',
                        params: {
                            appointment_id: this.appointment['id'],
                            amount: this.chosenPrice,
                        },
                        sessionHash: true,
                    }).toPromise();
                    this.loadingService.remove(this.loadingKey);
                    this.eventService.oCallFinishedSource.next();
                    this.dialogRef.close();
                } catch (error) {
                    this.loadingService.remove(this.loadingKey);
                    console.error(error);
                    this.eventService.print(
                        this.translate.instant('CALL.NOTIFICATION.SET_PRICE.ERROR.TITLE'),
                        this.translate.instant('CALL.NOTIFICATION.SET_PRICE.ERROR.DESC'),
                        'warn'
                    );
                }
            }  else {
                this.eventService.oCallFinishedSource.next();
                this.dialogRef.close();
                const activatePayments = this.notificationsService.error(this.translate.instant('EVENTS.activate_payments.TITLE'), this.translate.instant('EVENTS.activate_payments.DESC'), {
                    clickToClose: true
                });
                /* We do not use takeUntil(componentDestroyed) here as we dont want to stop this code from running */
                activatePayments.click.subscribe((event) => {
                    this.router.navigate([this.localize.translateRoute('/expert/payments')]);
                });
            }
        } else {
            this.eventService.oCallFinishedSource.next();
            this.dialogRef.close();
        }
    }
}
