import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

import { AuthGuard } from '../../shared/auth-guard.service';
import { ExpertComponent } from '../component';

const authRoutes: Routes = [
    {
        path: 'auth',
        component: ExpertComponent,
        children: [
            {
                path: 'register',
                loadChildren: () => import('./general-register-module').then(m => m.GeneralRegisterModule),
            },
            {
                path: 'login',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: false},
                loadChildren: () => import('./login/module').then(m => m.LoginModule),
            },
            {
                path: 'forgot',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: false},
                loadChildren: () => import('./password-forgotten/module').then(m => m.PasswordForgottenModule),
            },
            {
                path: 'reset/:emailForgotHash',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: false},
                loadChildren: () => import('./password-reset/module').then(m => m.PasswordResetModule),
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(authRoutes),
        LocalizeRouterModule.forChild(<any> authRoutes),
    ],
})
export class ExpertAuthModule {
}
