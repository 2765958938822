<a class="resize-menu-button"
    *ngIf="!isPhone && isMenuCollapsed"
    (click)="collapsingMenu()">
    <img src="../../../assets/img/openMenu.svg">
</a>

<div class="navigation-wrapper"
    [ngClass]="{'collapsing-navigation': isMenuCollapsed}">
    <a class="resize-menu-button"
        *ngIf="!isPhone && !isMenuCollapsed"
        (click)="collapsingMenu()">
        <img src="../../../assets/img/hideMenu.svg">
    </a>
    <div class="navigation-logo" *ngIf="!isPhone">
        <a (click)="toggleMenu('')"
            *ngIf="!customLogo && !waitingForLogo"
            class="logo"
            [routerLink]="'/expert/' | localize">
        </a>
        <a (click)="toggleMenu('')"
            *ngIf="customLogo"
            [routerLink]="'/expert/' | localize">
            <img 
                class="customlogo"
                [src]="customLogo">
        </a>
    </div>
    <div class="navigation-scroll-container">
        <ul class="main-menu" role="menu" no-bootstrap="1">
            <li class="menu-item">
                <a (click)="toggleMenu('')"
                    [routerLink]="'/expert/appointments' | localize"
                    routerLinkActive="active"
                >
                    <mat-icon>home_work</mat-icon>
                    <span [innerHTML]="'EXPERT.TABS.APPOINTMENTS' | translate"></span>
                </a>
            </li>
            <li class="menu-item">
                <a
                    (click)="toggleMenuFolder('availability')"
                    data-intercom-target="Availability">
                    <mat-icon>date_range</mat-icon>
                    <div class="collapsable-item">
                        <span [innerHTML]="'EXPERT.TABS.AVAILABILITY' | translate"></span>
                        <mat-icon *ngIf="openedMenuFolder !== 'availability'">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="openedMenuFolder === 'availability'">keyboard_arrow_down</mat-icon>
                    </div>
                </a>
            </li>
            <ng-container *ngIf="openedMenuFolder === 'availability'">
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('set_availability')"
                        [routerLink]="'/expert/availability' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="'EXPERT.TABS.SET_AVAILABILITY' | translate"></span>
                    </a>
                </li>
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('calendar_connections')"
                        [routerLink]="'/expert/calendar_connections' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="'EXPERT.TABS.CONNECT' | translate"></span>
                    </a>
                </li>
            </ng-container>

            <li class="menu-item" data-intercom-target="My Page">
                <a (click)="toggleMenu('customize_booking')"
                    [routerLink]="'/expert/customize_booking' | localize"
                    routerLinkActive="active"
                >
                    <mat-icon>phonelink</mat-icon>
                    <span [innerHTML]="'EXPERT.TABS.CUSTOMIZE_BOOKING' | translate"></span>
                </a>
            </li>

            <li class="menu-item">
                <a
                    (click)="toggleMenuFolder('offer')">
                    <mat-icon>assignment_ind</mat-icon>
                    <div class="collapsable-item">
                        <span [innerHTML]="(user['user_domain'] === 'app.saloncentral.com' ? 'EXPERT.TABS.OFFER_SALONCENTRAL' : 'EXPERT.TABS.OFFER') | translate"></span>
                        <mat-icon *ngIf="openedMenuFolder !== 'offer'">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="openedMenuFolder === 'offer'">keyboard_arrow_down</mat-icon>
                    </div>
                </a>
            </li>

            <ng-container *ngIf="openedMenuFolder === 'offer'">
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('set_offer')"
                        [routerLink]="'/expert/offer/all' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="(user['user_domain'] === 'app.saloncentral.com' ? 'EXPERT.TABS.OFFER_EDIT_SALONCENTRAL' : 'EXPERT.TABS.OFFER_EDIT') | translate"></span>
                    </a>
                </li>
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('forms')"
                        [routerLink]="'/expert/forms' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="'EXPERT.TABS.BOOKING_FORMS' | translate"></span>
                    </a>
                </li>
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('emails')"
                        [routerLink]="'/expert/emails' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="'EXPERT.TABS.EMAILS' | translate"></span>
                    </a>
                </li>
            </ng-container>

            <li class="menu-item">
                <a (click)="toggleMenu('contacts')"
                    [routerLink]="'/expert/contacts/all' | localize"
                    routerLinkActive="active"
                >
                    <mat-icon>people</mat-icon>
                    <span [innerHTML]="'EXPERT.TABS.CONTACTS' | translate"></span>
                </a>
            </li>
            <li class="menu-item" *ngIf="user['user_domain'] !== 'app.saloncentral.com'">
                <a (click)="toggleMenu('rooms')"
                    [routerLink]="'/expert/rooms' | localize"
                    routerLinkActive="active"
                >
                    <mat-icon>play_circle_outline</mat-icon>
                    <span [innerHTML]="'EXPERT.TABS.ROOMS' | translate"></span>
                </a>
            </li>
            <li class="menu-item">
                <a (click)="toggleMenu('payments')"
                    [routerLink]="'/expert/payments' | localize"
                    routerLinkActive="active"
                >
                    <mat-icon>payment</mat-icon>
                    <span [innerHTML]="'EXPERT.TABS.PAYMENTS' | translate"></span>
                </a>
            </li>
            <li class="menu-item"
                >
                <a (click)="toggleMenu('integrations')"
                    [routerLink]="'/expert/integrations' | localize"
                    routerLinkActive="active"
                >
                    <mat-icon>note_add</mat-icon>
                    <span [innerHTML]="'EXPERT.TABS.INTEGRATIONS' | translate"></span>
                </a>
            </li>
            <li class="menu-item" *ngIf="user.team_role === 0">
                <a
                    (click)="toggleMenuFolder('teams')">
                    <mat-icon>groups</mat-icon>
                    <div class="collapsable-item">
                        <span [innerHTML]="'EXPERT.TABS.TEAMS' | translate"></span>
                        <mat-icon *ngIf="openedMenuFolder !== 'teams'">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="openedMenuFolder === 'teams'">keyboard_arrow_down</mat-icon>
                    </div>
                </a>
            </li>

            <ng-container *ngIf="openedMenuFolder === 'teams'">
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('team_profile')"
                        [routerLink]="'/expert/team_profile' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="'EXPERT.TABS.TEAM_PROFILE' | translate"></span>
                    </a>
                </li>
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('team_members')"
                        [routerLink]="'/expert/team_members' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="'EXPERT.TABS.TEAM_MEMBERS' | translate"></span>
                    </a>
                </li>
                <li class="menu-item secondary-item">
                    <a class="light"
                        (click)="toggleMenu('team_page')"
                        [routerLink]="'/expert/team_page' | localize"
                        routerLinkActive="active"
                    >
                        <span [innerHTML]="'EXPERT.TABS.CUSTOMIZE_TEAM_BOOKING' | translate"></span>
                    </a>
                </li>
            </ng-container>

            <li class="menu-item">
                <a
                    *ngIf="shouldSeePackageItem()"
                    (click)="toggleMenu('subscription')"
                    class="upgrade"
                    [routerLink]="'/expert/subscription' | localize"
                    routerLinkActive="active"
                >
                <mat-icon>redeem</mat-icon>
                <span
                    [innerHTML]="'EXPERT.TABS.UPGRADE' | translate"></span>
                </a>
            </li>

            <ng-container *ngIf="isPhone">
                <li class="divider"></li>
                <!-- <li class="menu-item" *ngIf="shouldAccessInviteFriendPage()">
                    <a (click)="toggleMenu('invite_friend')"
                        [routerLink]="'/expert/invite_friend' | localize"
                        routerLinkActive="active">
                        <mat-icon>email</mat-icon>
                        <span [innerHTML]="'EXPERT.TABS.INVITE_FRIEND' | translate"></span>
                    </a>
                </li> -->
        
                <li class="menu-item" *ngIf="user.team_role === 0">
                    <a (click)="toggleMenu('subscription')"
                        [routerLink]="'/expert/subscription' | localize"
                        routerLinkActive="active">
                        <mat-icon>info</mat-icon>
                        <span [innerHTML]="'EXPERT.TABS.SUBSCRIPTION' | translate"></span>
                    </a>
                </li>
        
                <li class="menu-item">
                    <a (click)="toggleMenu('profile')"
                        [routerLink]="'/expert/profile' | localize"
                        routerLinkActive="active">
                        <mat-icon>settings</mat-icon>
                        <span [innerHTML]="'EXPERT.TABS.PROFILE' | translate"></span>
                    </a>
                </li>
        
                <li class="menu-item" *ngIf="!hideHelp">
                    <a (click)="toggleMenu('intercom')"
                        id="intercom-button"
                        [href]="intercomChatLink">
                        <mat-icon>help</mat-icon>
                        <span [innerHTML]="'EXPERT.TABS.HELP' | translate"></span>
                    </a>
                </li>
        
                <li class="menu-item">
                    <a (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span [innerHTML]="'HEADER.LOGOUT' | translate"></span>
                    </a>
                </li>
            </ng-container>

        </ul>
    </div>
    <div class="account" *ngIf="!isPhone">
        <img *ngIf="avatar"
            (mouseover)="settingsHover()"
            (mouseout)="settingsNoHover()"
            [src]="avatar"
            class="account-image">
        <img *ngIf="!avatar"
            (mouseover)="settingsHover()"
            (mouseout)="settingsNoHover()"
            class="account-image"
            src="../../../assets/img/avatar-placeholder.svg">        
    </div>

    <div class="account-settings" *ngIf="showSettings !== 0" (mouseover)="settingsHover()" (mouseout)="settingsNoHover()">

        <!-- <li (click)="showSettings = 0" class="menu-item menu-item-settings" *ngIf="shouldAccessInviteFriendPage()">
            <a [routerLink]="'/expert/invite_friend' | localize"
                routerLinkActive="active">
                <mat-icon>email</mat-icon>
                <span [innerHTML]="'EXPERT.TABS.INVITE_FRIEND' | translate"></span>
            </a>
        </li> -->

        <li (click)="showSettings = 0" class="menu-item menu-item-settings" *ngIf="user.team_role === 0">
            <a [routerLink]="'/expert/subscription' | localize"
                routerLinkActive="active">
                <mat-icon>info</mat-icon>
                <span [innerHTML]="'EXPERT.TABS.SUBSCRIPTION' | translate"></span>
            </a>
        </li>

        <li (click)="showSettings = 0" class="menu-item menu-item-settings">
            <a [routerLink]="'/expert/profile' | localize"
                routerLinkActive="active">
                <mat-icon>settings</mat-icon>
                <span [innerHTML]="'EXPERT.TABS.PROFILE' | translate"></span>
            </a>
        </li>

        <li class="menu-item menu-item-settings" *ngIf="!hideHelp">
            <a id="intercom-button"
                [href]="intercomChatLink">
                <mat-icon>help</mat-icon>
                <span [innerHTML]="'EXPERT.TABS.HELP' | translate"></span>
            </a>
        </li>

        <li class="menu-item menu-item-settings">
            <a (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span [innerHTML]="'HEADER.LOGOUT' | translate"></span>
            </a>
        </li>
    </div>
</div>
