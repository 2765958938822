import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class OnboardingService {

    user = null;
    onboarding_active = false;
    set_availability = false;
    customized_page = false;
    scheduled_meeting = false;
    did_integration = false;
    completion = 40;

    constructor(private apiService: ApiService,
        private authService: AuthService) {}

    initializer() {
        this.user = this.authService.user;
        if (this.user.onboarding_active) {
            this.apiService.request({
                method: 'get',
                btnIm: true,
                applicationHashPublic: true,
                url: '/users/gamification',
                sessionHash: true,
            }).subscribe((response) => {
                this.set_availability = !!response[0].set_availability;
                this.customized_page = !!response[0].customized_page;
                this.scheduled_meeting = !!response[0].scheduled_meeting;
                this.did_integration = !!response[0].did_integration;
                this.completion = 40 + (+this.set_availability + +this.customized_page + +this.scheduled_meeting + +this.did_integration) * 15;
                this.onboarding_active = this.completion === 100 ? false : !!response[0].onboarding_active;
                document.documentElement.style.setProperty('--onboarding-completion', (this.completion * 3.6).toString() + 'deg');

                /* Try/catch to avoid crashes because of tracking */
                try {
                    (window as any).analytics.identify(this.user.id, {
                        onboarding_completion: this.completion,
                        onboarding_set_availability: this.set_availability,
                        onboarding_customized_page: this.customized_page,
                        onboarding_scheduled_meeting: this.scheduled_meeting,
                        onboarding_did_integration: this.did_integration,
                    });
                } catch (err) {
                    console.error(err);
                }
            });
        } else {
            this.onboarding_active = false;
        }
    }

    stepCompleted(feature) {
        if (this.onboarding_active && !this[feature]) {
            this[feature] = true;
            this.completion = 40 + (+this.set_availability + +this.customized_page + +this.scheduled_meeting + +this.did_integration) * 15;
            // even if completion is 100%, this.onboarding_active should not get updated as we dont hide the onboarding section immediately
            document.documentElement.style.setProperty('--onboarding-completion', (this.completion * 3.6).toString() + 'deg');

            this.apiService.request({
                method: 'put',
                btnIm: true,
                applicationHashPublic: true,
                url: '/users/gamification',
                sessionHash: true,
                params: {
                    [feature]: 1,
                    percentage: this.completion,
                    onboarding_active: this.completion === 100 ? 0 : +this.onboarding_active,
                }
            }).subscribe(() => {
            }, (error) => {
                console.error(error);
            });
            /* Try/catch to avoid crashes because of tracking */
            try {
                (window as any).analytics.identify(this.user.id, {
                    onboarding_completion: this.completion,
                    onboarding_set_availability: this.set_availability,
                    onboarding_customized_page: this.customized_page,
                    onboarding_scheduled_meeting: this.scheduled_meeting,
                    onboarding_did_integration: this.did_integration,
                });
            } catch (err) {
                console.error(err);
            }
        }
    }

    deactivateOnboarding() {
        this.onboarding_active = false;
        this.apiService.request({
            method: 'put',
            btnIm: true,
            applicationHashPublic: true,
            url: '/users/gamification',
            sessionHash: true,
            params: {
                onboarding_active: 0,
            }
        }).subscribe(() => {
        }, (error) => {
            console.error(error);
        });
    }
}
