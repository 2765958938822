import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class LoadingService {
    changeSource: Subject<Array<string>> = new Subject<Array<string>>();
    change: Observable<Array<string>> = this.changeSource.asObservable();
    keys = [];

    constructor() {
    }

    public isLoading(key: string) {
        return this.keys.indexOf(key) >= 0;
    }

    public add(key: string) {
        if (this.isLoading(key) === false) {
            this.keys.push(key);
            this.updated();
        }
    }

    public remove(key: string) {
        const keyIndex = this.keys.indexOf(key);
        if (keyIndex >= 0) {
            this.keys.splice(keyIndex, 1);
            this.updated();
        }
    }

    private updated() {
        this.changeSource.next(this.keys);
    }
}
