import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import * as Cookies from 'js-cookie';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class EventService {
    socket: SocketIOClient.Socket;
    initialCookieHashPrivate;
    eventsTypes = [];
    notificationsList = {};

    oEventSource: Subject<any> = new Subject<any>();
    oCallFinishedSource: Subject<any> = new Subject<any>();
    oNotificationClosedSource: Subject<any> = new Subject<any>();
    socketStatusSource: Subject<boolean> = new Subject<boolean>();

    oEvent: Observable<any> = this.oEventSource.asObservable();
    oCallFinished: Observable<any> = this.oCallFinishedSource.asObservable();
    oNotificationClosed: Observable<any> = this.oNotificationClosedSource.asObservable();
    socketStatus: Observable<boolean> = this.socketStatusSource.asObservable();

    constructor(private translate: TranslateService,
                private notificationsService: NotificationsService) {
    }

    /**
     * Connect/Reconnect to socket
     */
    connect() {
        // Disconnect if socket opened
        if (this.socket) {
            this.disconnect();
        }
        // Generate connect URL
        let connectURL = environment.btnImApiBase;
        let current_domain = document.location.hostname;
        const params = {};
        const sessionHash = Cookies.get(environment.userHashCookieName, {
            path: environment.cookiePath,
            domain: current_domain,
            secure: environment.cookieSecure,
        });
        if (sessionHash) {
            params['session_hash'] = sessionHash;
        }
        this.initialCookieHashPrivate = Cookies.get(environment.hashPrivateCookieName, {
            path: environment.cookiePath,
            domain: current_domain,
            secure: environment.cookieSecure,
        });
        if (this.initialCookieHashPrivate) {
            params['hash_private'] = this.initialCookieHashPrivate;
        }
        if (Object.keys(params).length) {
            connectURL += '/?';
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    connectURL += key + '=' + params[key] + '&';
                }
            }
            connectURL = connectURL.substr(0, connectURL.length - 1);
        }
        // Connect
        this.socket = io(connectURL, {
            transports: ['websocket'],
        });
        this.addSocketConnectListener();
    }

    addSocketConnectListener() {
        this.socket.on('connect', () => {
            this.addSocketListeners();
            this.socketStatusSource.next(true);
        });
    }

    addSocketListeners() {
        /* Detection of when a socket is disconnected - This event is not sent by our backend but by the socket itself.
            It is important to keep so that we remove notifications once the socket is disconnected. */
        this.socket.on('disconnect', () => {
            this.socket.removeAllListeners();
            this.addSocketConnectListener();
            this.removeAll();
            this.socketStatusSource.next(false);
        });
        // On connection callback
        this.socket.on('session', (response) => {
            this.setHashPrivate(response['hash_private']);
        });

        this.eventsTypes = [
            {
                key: 'appointment_pending',
                type: 'info',
                url: 'appointments',
                title: this.translate.instant('EVENTS.appointment_pending.TITLE'),
                content: this.translate.instant('EVENTS.appointment_pending.DESC'),
            },
            {
                key: 'appointment_status_approved',
                type: 'info',
                url: 'appointments',
                title: this.translate.instant('EVENTS.appointment_status_approved.TITLE'),
                content: this.translate.instant('EVENTS.appointment_status_approved.DESC'),
            },
            {
                key: 'appointment_status_finished',
                type: 'info',
                url: 'appointments',
                title: this.translate.instant('EVENTS.appointment_status_finished.TITLE'),
                content: this.translate.instant('EVENTS.appointment_status_finished.DESC'),
            },
            {
                key: 'appointment_status_canceled',
                type: 'success',
                url: 'appointments',
                title: this.translate.instant('EVENTS.appointment_status_canceled.TITLE'),
                content: this.translate.instant('EVENTS.appointment_status_canceled.DESC'),
            },
            {
                key: 'appointment_soon',
                type: 'info',
                url: false,
                title: this.translate.instant('EVENTS.appointment_soon.TITLE'),
                content: this.translate.instant('EVENTS.appointment_soon.DESC'),
            },
            {
                key: 'stripe_account_not_found',
                type: 'error',
                url: 'payments',
                title: this.translate.instant('EVENTS.stripe_account_not_found.TITLE'),
                content: this.translate.instant('EVENTS.stripe_account_not_found.DESC'),
            },
            {
                key: 'core_subscription_failed',
                type: 'error',
                url: 'rooms',
                title: this.translate.instant('EVENTS.core_subscription_failed.TITLE'),
                content: this.translate.instant('EVENTS.core_subscription_failed.DESC'),
            },
            {
                key: 'cname_subscription_failed',
                type: 'error',
                url: 'profile',
                title: this.translate.instant('EVENTS.cname_subscription_failed.TITLE'),
                content: this.translate.instant('EVENTS.cname_subscription_failed.DESC'),
            },
            {
                key: 'group_subscription_failed',
                type: 'error',
                url: 'rooms',
                title: this.translate.instant('EVENTS.group_subscription_failed.TITLE'),
                content: this.translate.instant('EVENTS.group_subscription_failed.DESC'),
            },
            {
                key: 'recording_subscription_failed',
                type: 'error',
                url: 'rooms',
                title: this.translate.instant('EVENTS.recording_subscription_failed.TITLE'),
                content: this.translate.instant('EVENTS.recording_subscription_failed.DESC'),
            },
            {
                key: 'appointment_rescheduled_request',
                type: 'info',
                url: 'appointments',
                title: this.translate.instant('EVENTS.appointment_rescheduled_request.TITLE'),
                content: this.translate.instant('EVENTS.appointment_rescheduled_request.DESC'),
            },
            {
                key: 'appointment_rescheduled_confirmed',
                type: 'info',
                url: 'appointments',
                title: this.translate.instant('EVENTS.appointment_rescheduled_confirmed.TITLE'),
                content: this.translate.instant('EVENTS.appointment_rescheduled_confirmed.DESC'),
            },
        ];
        this.eventsTypes.forEach((eventType) => {
                this.socket.on(eventType.key, (event) => {
                    if (event['seen'] === null) {
                        /*
                         * Some logic here is temporarily disabled
                        */
                        if (eventType.key !== 'appointment_soon' && eventType.key !== 'appointment_status_approved' && eventType.key !== 'appointment_pending') {
                            const title = this.translate.instant('EVENTS.' + eventType['key'] + '.TITLE', event);
                            let content = this.translate.instant('EVENTS.' + eventType['key'] + '.DESC', event);
                            // to optimize appointments related notifications
                            if (eventType['url'] === 'appointments') {
                                // if notification of the same type already exists, remove it to prevent duplicates
                                if (this.notificationsList[eventType['key']]) {
                                    this.notificationsService.remove(this.notificationsList[eventType['key']]);
                                }
                                this.notificationsList[eventType['key']] = 'notification-' + event['id'] + '-' + eventType['key'];
                            }
                            this.notificationsService.create(title, content, eventType['type'], {
                                id: 'notification-' + event['id'] + '-' + eventType['key'],
                                clickToClose: true,
                            });
                        }
                        this.oEventSource.next({
                            type: eventType,
                            data: event,
                        });
                    }
                });
        });
    }

    /**
     * Set/Delete hashPrivate used for client management
     * @param sessionHashPrivate
     */
    setHashPrivate(sessionHashPrivate) {
        let current_domain = document.location.hostname;
        const currentCookieHashPrivate = Cookies.get(environment.hashPrivateCookieName, {
            path: environment.cookiePath,
            domain: current_domain,
            secure: environment.cookieSecure,
        });
        console.log(this.initialCookieHashPrivate);
        console.log(currentCookieHashPrivate);
        console.log(sessionHashPrivate);
        if (this.initialCookieHashPrivate !== currentCookieHashPrivate) {
            this.connect();
        } else if (currentCookieHashPrivate !== sessionHashPrivate) {
            Cookies.set(environment.hashPrivateCookieName, sessionHashPrivate, {
                expires: 90,
                path: environment.cookiePath,
                domain: current_domain,
                secure: environment.cookieSecure,
            });
        }
    }

    disconnect() {
        this.socket.close();
    }

    getEventTypeFromKey(key) {
        for (const eventType of this.eventsTypes) {
            if (eventType.key === key) {
                return eventType;
            }
        }
        return false;
    }

    closed($event) {
        const data = $event['id'].split('-');
        if (data.length === 3 && data[0] === 'notification') {
            this.socket.emit('seen', data[1]);
            const eventType = this.getEventTypeFromKey(data[2]);
            this.oNotificationClosedSource.next({
                type: eventType,
                data: data,
            });
        }
    }

    print(title, content = null, type = 'info', length = 10000) {
        /* this.notificationsService[type](title, content, {
            timeOut: length,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
        }); */
        this.notificationsService[type](title, content, {
            timeOut: length,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
        });
    }

    removeAll() {
        this.notificationsService.remove();
    }
}
