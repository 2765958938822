<div class="text-right close-button-div">
    <button mat-dialog-close class="close-button"></button>
</div>
<h2 class="text-center mt-0"
    [innerHTML]="'REGISTER.PACKAGES.REQUEST_UPGRADE.TITLE' | translate"></h2>
<p class="px-2 text-center">
    {{'REGISTER.PACKAGES.REQUEST_UPGRADE.DESC' | translate}}
</p>
<div class="d-flex justify-content-center">
    <app-form-button
        [type]="'button'"
        (o_click)="requestAdminToUpgrade()">
        {{'REGISTER.PACKAGES.REQUEST_UPGRADE.BUTTON' | translate}}
    </app-form-button>
</div>
