import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class WhiteLabelService {

    whiteLabelSettings = null;
    whiteLabelSettingsChecked = false;
    constructor(private apiService: ApiService) {}

    getWhiteLabelSettings(domain) {
        return new Promise((resolve, reject) => {
            this.apiService.request({
                method: 'get',
                btnIm: true,
                applicationHashPublic: true,
                url: '/white-label/app/',
                params: {
                    domain: domain,
                },
            }).subscribe((response) => {
                this.whiteLabelSettings = response;
                this.whiteLabelSettingsChecked = true;
                return resolve(response);
            }, () => {
                reject();
            });
        });
    }
}
