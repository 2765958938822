import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { SubscriptionService } from './subscription.service';
import { EventService } from './event.service';
import * as Cookies from 'js-cookie';
import * as moment from 'moment-timezone';
import { Subject ,  Observable, Subscription } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthService {

    user: any = false;
    loginStatusChangeSource: Subject<any> = new Subject<any>();
    openCallPriceSource: Subject<any> = new Subject<any>();
    openStartRecordingSource: Subject<any> = new Subject<any>();

    loginStatusChange: Observable<any> = this.loginStatusChangeSource.asObservable();
    openCallPrice: Observable<any> = this.openCallPriceSource.asObservable();
    openStartRecording: Observable<any> = this.openStartRecordingSource.asObservable();

    subscriptionSocketStatus: Subscription = null;

    constructor(private eventService: EventService,
                private apiService: ApiService,
                private subscriptionService: SubscriptionService) {
    }

    initializer() {
        return new Promise((resolve, reject) => {
            this.userGet().then(() => {
                if (this.user === false) {
                    this.socketConnect();
                }
                resolve();
            }, () => {
                reject();
            });
        });
    }

    socketConnect() {
        this.eventService.connect();
        if (!this.subscriptionSocketStatus) {
            this.subscriptionSocketStatus = this.eventService.socketStatus.subscribe((status: boolean) => {
                if (status === true) {
                    this.eventService.socket.on('login', () => {
                        this.userGet();
                    });
                    this.eventService.socket.on('logout', () => {
                        this.userSessionSet(false);
                        this.userBookingDomainSessionSet(false);
                        this.userSet(false);
                    });
                }
            });
        }
    }

    /**
     * Set/Delete user session
     * @param sessionHash
     */
    userSessionSet(sessionHash) {
        const current_domain = document.location.hostname;
        if (sessionHash !== false) {
            Cookies.set(environment.userHashCookieName, sessionHash, {
                expires: 30,
                path: environment.cookiePath,
                domain: current_domain,
                secure: environment.cookieSecure,
            });
        } else {
            Cookies.remove(environment.userHashCookieName, {
                path: environment.cookiePath,
                domain: current_domain,
                secure: environment.cookieSecure,
            });
        }
    }

    /**
     * Set/Delete user session in booking domain, useful for the calls expert auth
     * @param sessionHash
     */
    userBookingDomainSessionSet(sessionHash) {
        if (sessionHash !== false) {
            Cookies.set(environment.userHashCallCookieName, sessionHash, {
                expires: 30,
                path: environment.cookiePath,
                domain: environment.cookieDomain,
                secure: environment.cookieSecure,
            });
        } else {
            Cookies.remove(environment.userHashCallCookieName, {
                path: environment.cookiePath,
                domain: environment.cookieDomain,
                secure: environment.cookieSecure,
            });
        }
    }

    /**
     * Set/Delete user admin session
     * @param sessionHash
     */
    userAdminSessionSet(sessionHash) {
        const current_domain = document.location.hostname;
        if (sessionHash !== false) {
            Cookies.set(environment.userAdminHashCookieName, sessionHash, {
                expires: 30,
                path: environment.cookiePath,
                domain: current_domain,
                secure: environment.cookieSecure,
            });
        } else {
            Cookies.remove(environment.userAdminHashCookieName, {
                path: environment.cookiePath,
                domain: current_domain,
                secure: environment.cookieSecure,
            });
        }
    }

    /**
     * Return user session
     * @returns {string}
     */
    userSessionGet() {
        const current_domain = document.location.hostname;
        return Cookies.get(environment.userHashCookieName, {
            path: environment.cookiePath,
            domain: current_domain,
            secure: environment.cookieSecure,
        });
    }

    /**
     * Return admin user session
     * @returns {string}
     */
    userAdminSessionGet() {
        const current_domain = document.location.hostname;
        return Cookies.get(environment.userAdminHashCookieName, {
            path: environment.cookiePath,
            domain: current_domain,
            secure: environment.cookieSecure,
        });
    }

    /**
     * Set user
     * @param user
     */
    userSet(user) {
        this.user = user;
        if (this.user.timezone) {
            moment.tz.setDefault(this.user.timezone);
        }
        this.loginStatusChangeSource.next(this.user);
        this.socketConnect();
    }

    /**
     * If cookie is set check validity with API and fetch user object
     * @returns {Promise<any>}
     */
    userGet() {
        return new Promise((resolve, reject) => {
            if (this.userSessionGet()) {
                this.apiService.request({
                    method: 'get',
                    btnIm: true,
                    applicationHashPublic: true,
                    url: '/users/expert/',
                    sessionHash: true,
                }).subscribe((response) => {
                    this.userSet(response);
                    resolve();
                    this.subscriptionService.makeLtdAndDeleteFeatures();
                }, (error) => {
                    this.userSet(false);
                    this.userSessionSet(false);
                    this.userBookingDomainSessionSet(false);
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    loginAfterRegistrationDialog(data) {
        return new Promise((resolve, reject) => {
            this.userSessionSet(data['session_hash']);
            this.userBookingDomainSessionSet(data['session_hash']);
            this.eventService.socket.emit('login', () => {
                this.userSet(data);
                resolve();
            });
        });
    }

    updateStepRegistration(data) {
      return new Promise((resolve, reject) => {
          this.apiService.request({
            method: 'post',
            btnIm: true,
            applicationHashPublic: true,
            url: '/users/update-step-registration/',
            sessionHash: true,
            params: data,
            headers: false,
        }).subscribe(async (response) => {
            this.userSet(response['user']);
            resolve(response['user']);
        }, (errorData) => {
          const type = errorData && (errorData.error || {}).type;
          console.error(errorData);
          reject(type);
        });
      });
    }

    login(email, password) {
        return new Promise((resolve, reject) => {
            this.apiService.request({
                method: 'post',
                btnIm: true,
                applicationHashPublic: true,
                url: '/users/login/',
                params: {
                    email: email,
                    password: password,
                    role: 1,
                },
            }).subscribe((user) => {
                this.userSessionSet(user['session_hash']);
                this.userBookingDomainSessionSet(user['session_hash']);
                this.eventService.socket.emit('login', () => {
                    this.userSet(user);
                    resolve(user);
                });
                this.subscriptionService.makeLtdAndDeleteFeatures();
            }, () => {
                reject();
            });
        });
    }

    loginFromOneTimeCode(code) {
        return new Promise((resolve, reject) => {
            this.apiService.request({
                method: 'post',
                btnIm: true,
                applicationHashPublic: true,
                url: '/users/login/',
                params: {
                    code: code,
                    role: 1,
                },
            }).subscribe((user) => {
                this.userSessionSet(user['session_hash']);
                this.userBookingDomainSessionSet(user['session_hash']);
                this.eventService.socket.emit('login', () => {
                    this.userSet(user);
                    resolve(user);
                });
                this.subscriptionService.makeLtdAndDeleteFeatures();
            }, () => {
                reject();
            });
        });
    }

    logout() {
        this.userBookingDomainSessionSet(false);
        this.eventService.socket.emit('logout');
    }

    forgot(email, reCaptcha) {
        return this.apiService.request({
            method: 'put',
            url: '/users/forgot/',
            btnIm: true,
            params: {
                email: email,
                re_captcha: reCaptcha,
            },
            applicationHashPublic: true,
        });
    }

    reset(emailForgotHash, password) {
        return this.apiService.request({
            method: 'put',
            url: '/users/reset/',
            btnIm: true,
            params: {
                email_forgot_hash: emailForgotHash,
                password: password,
            },
        });
    }

    emailAvailable(email) {
        return this.apiService.request({
            method: 'get',
            btnIm: true,
            url: '/users/email/',
            params: {
                email: email,
            },
            applicationHashPublic: true,
        }).pipe(map(res => res === 0));
    }

    /**
     * Returns whether the length URL name is alredy used by the user or not
     */
    lengthSlugAvailable(length_slug) {
        return this.apiService.request({
            method: 'get',
            btnIm: true,
            url: '/users/length_slug/',
            params: {
                length_slug: length_slug,
            },
            applicationHashPublic: true,
            sessionHash: true,
        }).pipe(map(res => res));
    }

    /**
     * Returns whether the user is logged in
     */
    isLoggedIn() {
        return !!this.user;
    }
}
