import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
                private localize: LocalizeRouterService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        /* Types of Guards */
        const loginStatusShouldBe = typeof route.data.loginStatusShouldBe === 'undefined' ? false : !!route.data.loginStatusShouldBe;
        const needsPackage = typeof route.data.package === 'undefined' ? false : !!route.data.package;
        const userShouldBeFullyRegistered = typeof route.data.userShouldBeFullyRegistered === 'undefined' ? false : !!route.data.userShouldBeFullyRegistered;

        const isLoggedIn = this.authService.isLoggedIn();
        let isFullyRegistered = false;
        if (isLoggedIn) {
            isFullyRegistered = this.authService.user['active'] > 0 && this.authService.user['email_step'] > 3;
        }
        const hasPackageId = typeof this.authService.user['package_id'] !== 'undefined' && this.authService.user['package_id'] === parseInt(this.authService.user['package_id'], 10);
        const hasPackageCore = typeof this.authService.user['package_core'] !== 'undefined' && this.authService.user['package_core'] === parseInt(this.authService.user['package_core'], 10) &&
            parseInt(this.authService.user['package_core'], 10) > 0;

        const hasPackage = hasPackageId || hasPackageCore;
        // Requires to be logged in
        if (loginStatusShouldBe && !isLoggedIn) {
            this.router.navigate([this.localize.translateRoute('/auth/login')]);
            return false;
        }
        // Cannot visit page if logged in
        if (!loginStatusShouldBe && isLoggedIn) {
            this.router.navigate([this.localize.translateRoute('/')]);
            return false;
        }
        // Cannot visit page if logged in and not fully registered
        if (loginStatusShouldBe && isLoggedIn && userShouldBeFullyRegistered && !isFullyRegistered) {
            this.router.navigate([this.localize.translateRoute('/auth/register/2')]);
            return false;
        }
        // Cannot visit page if logged in and fully registered
        if (loginStatusShouldBe && isLoggedIn && !userShouldBeFullyRegistered && isFullyRegistered) {
            this.router.navigate([this.localize.translateRoute('/')]);
            return false;
        }

        // Is logged in but needs package
        if (isLoggedIn && needsPackage && !hasPackage) {
            this.router.navigate([this.localize.translateRoute('/expert/subscription')]);
            return false;
        }
        return true;
    }
}
