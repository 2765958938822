import { FormControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import {of as observableOf, timer as observableTimer,  Observable } from 'rxjs';
import {switchMap, map, debounceTime, distinctUntilChanged, take} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class ValidationService {
    messages = {};

    constructor(private translate: TranslateService,
                private http: HttpClient,
                private authService: AuthService,
                private apiService: ApiService) {
        this.messages = {
            required: _('VALIDATION.REQUIRED'),
            minlength: _('VALIDATION.MIN_LENGTH'),
            maxlength: _('VALIDATION.MAX_LENGTH'),
            max: _('VALIDATION.MAX_NUMBER_VALUE'),
            emailValid: _('VALIDATION.EMAIL_VALID'),
            emailExists: _('VALIDATION.EMAIL_EXIST'),
            emailAvailable: _('VALIDATION.EMAIL_AVAILABLE'),
            checksRequired: _('VALIDATION.CHECKS_REQUIRED'),
            phonePattern: _('VALIDATION.PHONE_PATTERN'),
            validAccountNumber: _('VALIDATION.ACCOUNT_NUMBER'),
            validAba: _('VALIDATION.ABA'),
            numberPositive: _('VALIDATION.NUMBER_POSITIVE'),
            numberBiggerThanZero: _('VALIDATION.NUMBER_BIGGER_THAN_ZERO'),
            isAdult: _('VALIDATION.IS_ADULT'),
            URLValid: _('VALIDATION.URL_VALID'),
            slugAvailable: _('VALIDATION.SLUG_AVAILABLE'),
            slugValid: _('VALIDATION.SLUG_VALID'),
            teamSlugAvailable: _('VALIDATION.TEAM_SLUG_AVAILABLE'),
            validDate: _('VALIDATION.VALID_DATE'),
            checkRoomNamesPerUser: _('VALIDATION.ROOM_NAME_UNIQUE'),
            checkLengthSlugPerUser: _('VALIDATION.LENGTH_SLUG_UNIQUE'),
            roomNameValid: _('VALIDATION.ROOM_NAME_VALID'),
            matDatepickerParse: '', // Doesn't need key, simple placeholder
            bookingDomainStartingByApp: _('VALIDATION.BOOKING_DOMAIN_STARTS_BY_APP'),
            checkLengthURLNamesPerUser: _('VALIDATION.LENGTH_URL_NAME_UNIQUE'),
            collectiveMembersLimit: _('OFFER.TOO_MANY_MEMBERS'),
        };
    }

    getErrorMessages(errors) {
        const error_messages = [];
        if (errors != null) {
            const error_keys = Object.keys(errors);
            for (const key of error_keys) {
                if (this.messages[key]) {
                    const values = typeof errors[key] === 'object' ? errors[key] : {};
                    error_messages.push(this.translate.instant(this.messages[key], values));
                } else {
                    console.log('No message for validation key: ' + key);
                }
            }
            return error_messages;
        } else {
            return error_messages;
        }
    }

    getErrorMessage(errors) {
        const error_messages = this.getErrorMessages(errors);
        return error_messages.length > 0 ? error_messages[0] : '';
    }

    emailValid(c: FormControl) {
        // RFC 2822 compliant regex
        if (c.value === '' || c.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return null;
        } else {
            return {emailValid: true};
        }
    }

    URLValid(c: FormControl) {
        // RFC 2822 compliant regex
        if (c.value === '' || /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,6})?(\/.*)?$/.test(c.value)) {
            return null;
        } else {
            return {URLValid: true};
        }
    }

    emailAvailable(c: FormControl) {
        return observableTimer(200).pipe(
            switchMap(() => {
                return this.authService.emailAvailable(c.value).pipe(map((res) => {
                    return res === true ? null : {emailAvailable: true};
                }, (error) => {
                    console.error(error);
                    return {emailAvailable: true};
                }));
            }));
    }

    emailAvailableCountingYourOwn(c: FormControl) {
        if (!c.valueChanges || c.pristine) {
            return observableOf(null);
        } else {
            return observableTimer(200).pipe(
                switchMap(() => {
                    return this.authService.emailAvailable(c.value).pipe(map((res) => {
                        const currentUserEmail = this.authService.user['email'];
                        return res === true || (currentUserEmail === c.value) ? null : {emailAvailable: true};
                    }, (error) => {
                        console.error(error);
                        return {emailAvailable: true};
                    }));
                }));
        }
    }

    slugValid(c: FormControl) {
        const regex = new RegExp(/^[0-9a-z-]*$/g);
        return regex.test(c.value) ? null : {slugValid: true};
    }

    slugAvailable(c: FormControl) {
        // todo: workaround https://github.com/angular/angular/issues/13200
        if (!c.valueChanges || c.pristine) {
            return observableOf(null);
        } else {
            return c.valueChanges.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                take(1),
                switchMap(value => this.apiService.request({
                    method: 'get',
                    btnIm: true,
                    url: '/users/slug/',
                    params: {
                        slug: value,
                    },
                    applicationHashPublic: true,
                    sessionHash: true,
                })),
                map((response) => {
                    return response === true ? null : {slugAvailable: true};
                }, (error) => {
                    console.error(error);
                    return {slugAvailable: true};
                }), );
        }
    }

    teamSlugAvailable(c: FormControl) {
        // todo: workaround https://github.com/angular/angular/issues/13200
        if (!c.valueChanges || c.pristine) {
            return observableOf(null);
        } else {
            return c.valueChanges.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                take(1),
                switchMap(value => this.apiService.request({
                    method: 'get',
                    btnIm: true,
                    url: '/teams/slug/',
                    params: {
                        slug: value,
                    },
                    applicationHashPublic: true,
                    sessionHash: true,
                })),
                map((response) => {
                    return response === true ? null : {teamSlugAvailable: true};
                }, (error) => {
                    console.error(error);
                    return {teamSlugAvailable: true};
                }), );
        }
    }

    emailExists(c: FormControl) {
        return observableTimer(200).pipe(
            switchMap(() => {
                return this.authService.emailAvailable(c.value).pipe(map((res) => {
                    return res === false ? null : {emailExists: true};
                }, (error) => {
                    return {emailExists: true};
                }));
            }));
    }

    checksRequired(minimum) {
        return (g: FormGroup) => {
            let checked = 0;
            if (g['controls']) {
                const keys = Object.keys(g['controls']);
                for (const key of keys) {
                    if (g['controls'][key]['value'] === true) {
                        checked++;
                    }
                    if (checked >= minimum) {
                        return null;
                    }
                }
            }
            return {
                checksRequired: {
                    checksRequired: minimum,
                }
            };
        };
    }

    numberPositive(c: FormControl) {
        return c.value >= 0 ? null : {numberPositive: true};
    }

    phonePattern(c: FormControl) {
      if (c.value.toString().length > 0) {
        const regex = new RegExp(/^[ ]{0,1}[0-9-/()x+. #]{5,18}$/g);
        return regex.test(c.value) ? null : {phonePattern: true};
      }
    }

    validAccountNumber(c: FormControl) {
        const regex = new RegExp(/^[0-9]*$/);
        return regex.test(c.value) ? null : {validAccountNumber: true};
    }

    validAba(c: FormControl) {
        const regex = new RegExp(/^[0-9]*$/);
        return regex.test(c.value) ? null : {validAba: true};
    }

    numberBiggerThanZero(c: FormControl) {
        return c.value > 0 ? null : {numberBiggerThanZero: true};
    }

    isAdult(c: FormControl) {
        const age = moment().diff(moment(c.value), 'years');
        return age >= 18 ? null : {isAdult: true};
    }

    validDate(format, optionalFormat = null) {
        return (c: FormControl) => {
            const dateMoment = moment(c.value, format).format(format);
            if (optionalFormat) {
                const dateMomentOptional = moment(c.value, optionalFormat).format(optionalFormat);
                return dateMoment === c.value || dateMomentOptional === c.value ? null : {validDate: true};
            }
            return dateMoment === c.value ? null : {validDate: true};
        };
    }

    checkRoomNamesPerUser(rooms) {
        return (g: FormGroup) => {
            if (g.value.length > 0) {
                return rooms.filter(room => room.name === g.value).length > 0 ? { checkRoomNamesPerUser: true } : null;
            }
        };
    }

    roomNameValid(c: FormControl) {
        const regex = new RegExp(/^[0-9a-z-]*$/g);
        return regex.test(c.value) ? null : {roomNameValid: true};
    }

    bookingDomainStartingByApp(c: FormControl) {
        if (c.value.startsWith('app.')) {
            return {bookingDomainStartingByApp: true};
        }
        return null;
    }

    collectiveMembersLimit(c: FormControl) {
        // collective is only allowed for users with 16 participants
        // allow up to 15 experts to be part of collective meeting type (1 guest at least)
        if (c.value.length > 15) {
            return {collectiveMembersLimit: true};
        }
        return null;
    }

    checkLengthSlugPerUser(lengths, id = null) {
        return (c: FormGroup) => {
            if (!c.valueChanges || c.pristine || c.value.length === 0) {
                return null;
            }
            if (id) {
                return lengths.filter(length => length.id !== id && length.length_slug === c.value).length > 0 ? { checkLengthSlugPerUser: true } : null;
            }
            return lengths.filter(length => length.length_slug === c.value).length > 0 ? { checkLengthSlugPerUser: true } : null;
        };
    }

    connectedZoom(connectedZoom) {
        return (c: FormGroup) => {
            if (c.value === 'zoom' && connectedZoom === false) {
                return {connectedZoom: true};
            } else {
                return null;
            }
        };
    }
}
