import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class SubscriptionService {

    isChecked = false;
    constructor(private apiService: ApiService) {}

    makeLtdAndDeleteFeatures() {
        if (!this.isChecked) {
            this.apiService.request({
                method: 'put',
                btnIm: true,
                url: '/stripe/subscriptions/features-removal-and-ltd/',
                sessionHash: true,
            }).subscribe((response) => {
                this.isChecked = true;
            }, () => {
            });
        }
    }
}
