import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from '../../../../shared/loading.service';
import { EventService } from '../../../../shared/event.service';
import { ApiService } from '../../../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-expert-request-upgrade',
    templateUrl: './component.html'
})
export class RequestUpgradeComponent {
    feature = '';
    loadingKey = 'submitUpgradeRequest';

    constructor(private loadingService: LoadingService,
                private eventService: EventService,
                private apiService: ApiService,
                private translate: TranslateService,
                public dialogRef: MatDialogRef<RequestUpgradeComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.feature = data.feature;
    }

    requestAdminToUpgrade() {
        this.dialogRef.close();
        if (this.loadingService.isLoading(this.loadingKey) === false) {
            this.loadingService.add(this.loadingKey);
            this.apiService.request({
                method: 'post',
                btnIm: true,
                url: '/features/request-upgrade/',
                sessionHash: true,
                applicationHashPublic: true,
                params: {feature: this.feature.toUpperCase()},
            }).subscribe(() => {
                this.loadingService.remove(this.loadingKey);
                this.eventService.print(
                    this.translate.instant('REGISTER.PACKAGES.REQUEST_UPGRADE.NOTIFICATION.SUCCESS.TITLE'),
                    this.translate.instant('REGISTER.PACKAGES.REQUEST_UPGRADE.NOTIFICATION.SUCCESS.DESC'),
                    'success'
                );
                /* Try/catch to avoid crashes because of tracking */
                try {
                    (window as any).analytics.track('Requested paid feature', {
                        feature: this.feature,
                    });
                } catch (err) {
                    console.error(err);
                }
            }, (error) => {
                console.error(error);
                this.eventService.print(
                    this.translate.instant('REGISTER.PACKAGES.REQUEST_UPGRADE.NOTIFICATION.ERROR.TITLE'),
                    this.translate.instant('REGISTER.PACKAGES.REQUEST_UPGRADE.NOTIFICATION.ERROR.DESC'),
                    'warn'
                );
                this.loadingService.remove(this.loadingKey);
            });
        }
    }
}
