import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../../shared/api.service';

@Component({
    selector: 'app-start-recording',
    templateUrl: './call-start-recording.component.html',
    styleUrls: ['./call-start-recording.component.css']
})

export class CallSatrtRecordingComponent implements OnInit {

    startRecording = false;
    showRecordings = false;
    recordings: any;
    appointmentId;
    loading = false;
    recordingError = false;

    constructor(public dialogRef: MatDialogRef<CallSatrtRecordingComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService) {
                    const { startRecording, showRecordings, appointmentId } = data;
                    this.startRecording = startRecording;
                    this.showRecordings = showRecordings;
                    this.appointmentId = appointmentId;
    }

    ngOnInit() {
        if (this.showRecordings) {
            this.loading = true;
            this.apiService.request({
                method: 'post',
                btnIm: true,
                url: '/appointments/archive-recordings/',
                sessionHash: true,
                params: {appointment_id: this.appointmentId},
            }).subscribe((response) => {
                this.loading = false;
                this.recordings = response;
                this.recordings.map(recording => {
                    if (!recording.url) {
                        this.recordingError = true;
                    }
                });
            }, (error) => {
                console.error(error);
            });
        }
    }

    close() {
        this.dialogRef.close();
    }
}
