import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as Cookies from 'js-cookie';
import { ErrorService } from './error.service';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CustomEncoder} from './custom.encoder';

@Injectable()
export class ApiService {

    constructor(private translate: TranslateService,
                private http: HttpClient,
                private errorService: ErrorService) {
    }

    fakeUpload(file, name) {
        const body = new FormData();
        // Safari fix
        // FormData.append must be an instance of Blob
        // file = new Blob([file], {type: 'image/png'});
        body.append('avatar', file, name);
        return this.http.post(environment.btnImAppBase + '/uploads/', body);
    }

    request(data) {
        data = Object.assign({
            method: 'get',
            btnIm: false,
            url: '',
            params: {},
            upload: false,
            uploadFiles: false,
            sessionHash: false,
            applicationHashPublic: false,
            headers: false,
        }, data);
        const url = (data.btnIm === true ? environment.btnImApiBase : environment.apiBase) + data.url;
        let body;
        /**
         * Headers
         */
        const headers = {};
        if (typeof this.translate.currentLang !== 'undefined') {
            headers['locale_code'] = this.translate.currentLang;
        }
        if (data.applicationHashPublic === true) {
            headers['application_hash_public'] = environment.hashPublic;
        }
        if (data.method === 'post' && data.upload === false) {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        /**
         * Params
         */
        if (data.method === 'post' || data.method === 'put') {
            if (data.upload === false) {
                body = new HttpParams({encoder: new CustomEncoder()});
                let current_domain = document.location.hostname;
                if (data.sessionHash === true) {
                    body = body.set('session_hash', Cookies.get(environment.userHashCookieName, {
                        path: environment.cookiePath,
                        domain: current_domain,
                        secure: environment.cookieSecure,
                    }));
                }
                for (const key in data.params) {
                    if (data.params.hasOwnProperty(key)) {
                        body = body.set(key, data.params[key]);
                    }
                }
            } else {
                body = new FormData();
                if (data.sessionHash === true) {
                    let current_domain = document.location.hostname;
                    body.append('session_hash', Cookies.get(environment.userHashCookieName, {
                        path: environment.cookiePath,
                        domain: current_domain,
                        secure: environment.cookieSecure,
                    }));
                }
                for (const key in data.uploadFiles) {
                    if (data.uploadFiles.hasOwnProperty(key)) {
                        // Safari fix
                        // FormData.append must be an instance of Blob
                        const name = data.uploadFiles[key]['name'];
                        const file = new Blob([data.uploadFiles[key]['file']], {type: 'image/png'});
                        body.append(key, file, name);
                    }
                }
                for (const key in data.params) {
                    if (data.params.hasOwnProperty(key)) {
                        body.append(key, data.params[key]);
                    }
                }
            }
        } else if (data.method === 'get' || data.method === 'delete') {
            if (data.sessionHash === true) {
                let current_domain = document.location.hostname;
                data.params['session_hash'] = Cookies.get(environment.userHashCookieName, {
                    path: environment.cookiePath,
                    domain: current_domain,
                    secure: environment.cookieSecure,
                });
            }
        }
        /**
         * Request
         */
        const requestOptions = {
            headers: headers,
        };
        if (data.headers === true) {
            requestOptions['observe'] = 'response';
        }
        if (data.method === 'post') {
            return this.http.post(url, body, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(error);
            }));
        } else if (data.method === 'put') {
            return this.http.put(url, body, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(error);
            }));
        } else if (data.method === 'delete') {
            requestOptions['params'] = data.params;
            return this.http.delete(url, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(error);
            }));
        } else if (data.method === 'get') {
            requestOptions['params'] = data.params;
            return this.http.get(url, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(error);
            }));
        }
    }
}
