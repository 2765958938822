import { NgModule } from '@angular/core';
import { FormModule } from '../../form/form.module';
import { FormButtonModule } from '../../form/button/module';
import { DialogConfirmComponent } from './component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        FormModule,
        FormButtonModule,
        MatDialogModule,
    ],
    declarations: [
        DialogConfirmComponent,
    ],
    exports: [
        DialogConfirmComponent,
    ],
})
export class DialogConfirmModule {
}
