import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-form-button',
    template: `
        <button
            type="{{type}}"
            (click)="o_click.emit($event)"
            [disabled]="disabled"
            class="{{size}}"
            [ngClass]="{secondary: color === 'secondary', tertiary: color === 'tertiary', green: color === 'green', red: color === 'red', delete: color === 'delete', full: full }"
        >
            <ng-content></ng-content>
        </button>
    `,
    styles: [`
        button {
            padding: 8px 25px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            line-height: 25px;
            font-weight: 500;
            background-color: var(--color-primary);
            transition: background .3s;
            color: white;
            min-width: 150px;
            border: 1px solid var(--color-primary);
        }
        button.secondary {
            background-color: white;
            color: var(--color-primary);
            border: 1px solid var(--color-primary);
        }
        button.secondary:hover, button.secondary:focus {
            color: white;
        }
        button.tertiary {
            padding: 0;
            min-width: initial;
            background-color: white;
            color: var(--color-primary);
            border: 1px solid transparent;
        }
        button.tertiary:hover, button.tertiary:focus {
            text-decoration: underline;
            background-color: white;
            border: 1px solid transparent;
        }
        button.big {
            padding: 10px 30px;
            font-size: 18px;
            line-height: 25px;
            font-weight: 500;
        }
        button:hover {
            background-color: var(--color-primary-light);
            border: 1px solid var(--color-primary-light);
        }
        button:focus {
            background: var(--color-primary-dark);
            border: 1px solid var(--color-primary-dark);
        }
        button.tertiary:disabled {
            background-color: white;
            border: 1px solid transparent;
            color: var(--color-tertiary-disabled);
        }
        button.green {
            color: white;
            background-color: var(--color-green-light);
            border: 1px solid var(--color-green-light);
        }
        button.green:hover {
            background-color: var(--color-green);
            border: 1px solid var(--color-green);
        }
        button.green:focus {
            background-color: var(--color-green-dark);
            border: 1px solid var(--color-green-dark);
        }
        button.red {
            background-color: white;
            color: var(--color-red-light);
            border: 1px solid var(--color-red-light);
        }
        button.delete {
            color: white;
            background-color: var(--color-red-light);
            border: 1px solid var(--color-red-light);
        }
        button.red:hover, button.delete:hover {
            color: white;
            background-color: var(--color-red);
            border: 1px solid var(--color-red);
        }
        button.red:focus, button.delete:focus  {
            color: white;
            background-color: var(--color-red-dark);
            border: 1px solid var(--color-red-dark);
        }
        button:disabled {
            cursor: default;
            background-color: var(--color-disabled) !important;
            border: 1px solid var(--color-disabled) !important;
            color: white;
        }
        .full {
            width: 100%;
        }
    `]
})

export class FormButtonComponent {
    @Input() type = 'button';
    @Input() disabled = false;
    @Input() size = '';
    @Input() color = '';
    @Input() full = false;
    @Output() o_click = new EventEmitter();
}
