import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';

@Component({
    selector: 'app-expert-warning-update',
    templateUrl: './component.html'
})
export class WarningUpgradeComponent {
    description = '';
    constructor(private localize: LocalizeRouterService,
                private router: Router,
                public dialogRef: MatDialogRef<WarningUpgradeComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.description = data.description;
    }

    redirectToSubscriptionPage() {
        this.dialogRef.close();
        this.router.navigate([this.localize.translateRoute('/expert/subscription/')]);
    }
}
