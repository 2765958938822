import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '../../../../form/form.module';
import { FormButtonModule } from '../../../../form/button/module';
import { WarningUpgradeComponent } from './component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        TranslateModule,
        FormModule,
        FormButtonModule,
        MatDialogModule,
    ],
    declarations: [
        WarningUpgradeComponent,
    ],
    exports: [
        WarningUpgradeComponent,
    ],
})
export class WarningUpgradeModule {
}
