<div class="text-right close-button-div">
    <button mat-dialog-close class="close-button"></button>
</div>
<div class="row mt-0 justify-content-center text-center"
     *ngIf="priceMax > 0">
    <h3 class="col-12 mt-0"
        [innerHTML]="'CALL.PRICE.TITLE' | translate"></h3>
    <p class="col-12"
       [innerHTML]="'CALL.PRICE.DESC' | translate:{length: length, priceMax: priceMaxFormatted}"></p>
    <form class="col-sm-6"
        novalidate
        (submit)="submit()">
        <mat-form-field class="price">
            <input type="number"
                   autocomplete="off"
                   name="chosenPrice"
                   matInput
                   [(ngModel)]="chosenPrice"
                   required>
            <span matSuffix class="currency">{{appointment['currency_code'].toUpperCase()}}</span>
        </mat-form-field>
        <app-form-button
            [type]="'submit'"
            [size]="'big'"
            [disabled]="this.chosenPrice < 0 || this.chosenPrice > this.priceMax || disableSubmit">
            {{'CALL.PRICE.SUBMIT' | translate}}
        </app-form-button>
    </form>
</div>
<div class="row justify-items-center text-center"
     *ngIf="priceMax === 0">
    <h2 class="col-12"
        [innerHTML]="'CALL.PRICE.FREE.TITLE' | translate"></h2>
    <p class="col-12"
       [innerHTML]="'CALL.PRICE.FREE.DESC' | translate"></p>
    <div class="col-12">
        <app-form-button
            (o_click)="submit()">
            {{'CALL.PRICE.FREE.CLOSE' | translate}}
        </app-form-button>
    </div>
</div>