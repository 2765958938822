import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ApiService } from './api.service';

@Injectable()
export class SuperAdminGuard implements CanActivate {

    constructor(private apiService: ApiService,
                private localize: LocalizeRouterService,
                private router: Router) {
    }


    async canActivate(route: ActivatedRouteSnapshot) {
        const is_super_admin = await this.apiService.request({
            method: 'get',
            btnIm: true,
            url: '/users/is_super_admin',
            sessionHash: true,
        }).toPromise();

        if (!is_super_admin) {
            this.router.navigate([this.localize.translateRoute('/expert/appointments')]);
            return false;
        }
        return true;
    }
}
