import { Component, Inject, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import * as locales from '../assets/locales.json';
import { EventService } from './shared/event.service';
import { AuthService } from './shared/auth.service';
import { LoadingService } from './shared/loading.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogHelper } from './shared/dialog.helper';
import * as moment from 'moment-timezone';
import { DOCUMENT } from '@angular/common';
import { ChargeCallPriceComponent } from './expert/call/charge-guest-modal/charge-call-price.component';
import { CallSatrtRecordingComponent } from './expert/call/recording/call-start-recording.component';
import { environment } from '../environments/environment';
import { ApiService } from './shared/api.service';
import { NotificationsService } from 'angular2-notifications';
import { WhiteLabelService } from './shared/white-label.service';
import { Title } from '@angular/platform-browser';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    template: `
        <simple-notifications
            [options]="notificationOptions"
            (destroy)="eventClose($event)"
            *ngIf="!(borderless || groupCall || roomCall)"></simple-notifications>
        <mat-progress-bar
            mode="indeterminate"
            *ngIf="loading"
            style="position: fixed; top: 0; left: 0; width: 100%; z-index: 9999"
        ></mat-progress-bar>
        <router-outlet></router-outlet>
        <button mat-button
            color="primary"
            class="return-button"
            *ngIf="adminSession"
            (click)="backToAdmin()">
            <mat-icon>arrow_back</mat-icon>
            {{'TEAMS_MEMBERS.ADMIN_ACCESS.RETURN_TO_ADMIN' | translate}}
        </button>
    `,
    styles: [`
        html {
            overflow-x: hidden;
            overflow-y: auto;
        }
    `],
})
export class AppComponent extends OnDestroyMixin implements OnDestroy {
    loading = false;
    notificationOptions = {
        position: ['top', 'right'],
        timeOut: 0,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        maxStack: 3,
        icons: {
            success: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            alert: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            error: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            info: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            warn: '<i class="material-icons" style="font-size: 2rem;">close</i>'
        },
    };
    borderless = false;
    groupCall: Boolean = false;
    roomCall: Boolean = false;
    adminSession: String;
    hideIntercom = true;

    constructor(private localize: LocalizeRouterService,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private eventService: EventService,
                private translate: TranslateService,
                private loadingService: LoadingService,
                public dialog: MatDialog,
                private dialogHelper: DialogHelper,
                private apiService: ApiService,
                private whiteLabelService: WhiteLabelService,
                private notificationsService: NotificationsService,
                private titleService: Title,
                @Inject(DOCUMENT) private document: any) {
        super();
        if (this.whiteLabelService.whiteLabelSettingsChecked) {
            if (this.whiteLabelService.whiteLabelSettings) {
                this.hideIntercom = this.whiteLabelService.whiteLabelSettings.hide_intercom !== 1 ? false : true;
                if (this.whiteLabelService.whiteLabelSettings.default_tab_title && this.whiteLabelService.whiteLabelSettings.default_tab_title.length > 0) {
                    this.titleService.setTitle(this.whiteLabelService.whiteLabelSettings.default_tab_title);
                }
                if (this.whiteLabelService.whiteLabelSettings.faviconWhiteLabelSetting) {
                    const link = document.createElement('link');
                    const oldLink = document.getElementById('dynamic-favicon');
                    link.id = 'dynamic-favicon';
                    link.rel = 'shortcut icon';
                    link.href = this.whiteLabelService.whiteLabelSettings.faviconWhiteLabelSetting;
                    if (oldLink) {
                        document.head.removeChild(oldLink);
                    }
                    document.head.appendChild(link);
                }
                this.hideIntercom = this.whiteLabelService.whiteLabelSettings.hide_intercom !== 1 ? false : true;
            } else {
                this.hideIntercom = false;
            }
        } else {
            const domain = document.location.hostname;
            if (domain !== environment.baseUrlWithoutHttp && environment.production) {
                this.whiteLabelService.getWhiteLabelSettings(domain).then(
                    (response: any) => {
                        if (response.hide_intercom !== 1) {
                            this.hideIntercom = false;
                        }
                        if (response.default_tab_title && response.default_tab_title.length > 0) {
                            this.titleService.setTitle(response.default_tab_title);
                        }
                        if (response.faviconWhiteLabelSetting) {
                            const link = document.createElement('link');
                            const oldLink = document.getElementById('dynamic-favicon');
                            link.id = 'dynamic-favicon';
                            link.rel = 'shortcut icon';
                            link.href = response.faviconWhiteLabelSetting;
                            if (oldLink) {
                                document.head.removeChild(oldLink);
                            }
                            document.head.appendChild(link);
                        }
                    },
                    () => {
                        this.hideIntercom = false;
                    }
                );
            } else {
                this.hideIntercom = false;
            }
        }
        router.events.pipe(takeUntil(componentDestroyed(this))).subscribe((event) => {
            // Page loading
            if (event instanceof NavigationStart) {
                loadingService.add('navigation');
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                loadingService.remove('navigation');
            }
            // Scroll to top on url change
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
            // No language fix
            if (event instanceof RoutesRecognized && ['', '/'].indexOf(event.url) < 0 && event.url !== event.urlAfterRedirects) {
                // Check if URL contains language
                const regexp = '^\\/?(' + locales['locales'].join('|') + ')\\/?';
                if (event.url.match(regexp) === null) {
                    this.router.navigate([this.localize.translateRoute(event.url)]);
                }
            }

            if (event instanceof NavigationEnd) {
                // Check if URL contains different special words
                const regexp = 'borderless';
                if (event.url.match(regexp)) {
                    this.borderless = true;
                }
                if (event.url.includes('group')) {
                    this.groupCall = true;
                }
                if (event.url.includes('room') && !event.url.includes('rooms')) {
                    this.roomCall = true;
                }
                const { snapshot: { queryParams: { hash = '' }} }: any = route;
                if (!this.authService.isLoggedIn() && hash) {
                    /**
                     * Get user by hash
                     */
                    this.authService.userSessionSet(hash);
                    location.reload();
                }
                // Language fix in case user locale_code is not taken into consideration
                if (this.authService.user && this.translate.currentLang !== this.authService.user['locale_code']) {
                    this.localize.changeLanguage(this.authService.user['locale_code']);
                }
            }
        });
        loadingService.change.pipe(takeUntil(componentDestroyed(this))).subscribe((keys: Array<string>) => {
            this.loading = keys.length > 0;
        });
        this.translate.onLangChange.pipe(takeUntil(componentDestroyed(this))).subscribe((event: LangChangeEvent) => {
            moment.locale(event.lang);
        });
        moment.locale(this.translate.currentLang);

        authService.openCallPrice.pipe(takeUntil(componentDestroyed(this))).subscribe((data: any = null) => {
            this.dialog.open(ChargeCallPriceComponent, {
              // width: '80%',
              maxWidth: '850px',
              data
          });
        });

        authService.openStartRecording.pipe(takeUntil(componentDestroyed(this))).subscribe((data: any = null) => {
            this.dialog.open(CallSatrtRecordingComponent, {
              maxWidth: '850px',
              data
            });
        });

        this.eventService.oNotificationClosed.pipe(takeUntil(componentDestroyed(this))).subscribe((data: any) => {
            if (data['type']['url'] === 'appointments') {
                this.router.navigate([this.localize.translateRoute('/expert/appointments/')]);
            }
        });

        if (this.authService.user && this.authService.user.dashboard_color) {
            document.documentElement.style.setProperty('--dashboard-color', this.authService.user.dashboard_color);
            document.documentElement.style.setProperty('--dashboard-color-10-opacity', this.authService.user.dashboard_color + '10');
            document.documentElement.style.setProperty('--dashboard-color-20-opacity', this.authService.user.dashboard_color + '20');
        } else {
            document.documentElement.style.setProperty('--dashboard-color', '#00C6FF');
            document.documentElement.style.setProperty('--dashboard-color-10-opacity', '#00C6FF10');
            document.documentElement.style.setProperty('--dashboard-color-20-opacity', '#00C6FF20');
        }
        if (this.authService.user && this.authService.user.dashboard_text_color) {
            document.documentElement.style.setProperty('--dashboard-text-color', this.authService.user.dashboard_text_color);
        } else {
            document.documentElement.style.setProperty('--dashboard-text-color', 'white');
        }
        this.authService.loginStatusChange.pipe(takeUntil(componentDestroyed(this))).subscribe((user: any) => {
            if (user && user.dashboard_color) {
                document.documentElement.style.setProperty('--dashboard-color', user.dashboard_color);
                document.documentElement.style.setProperty('--dashboard-color-10-opacity', user.dashboard_color + '10');
                document.documentElement.style.setProperty('--dashboard-color-20-opacity', user.dashboard_color + '20');
            } else {
                document.documentElement.style.setProperty('--dashboard-color', '#00C6FF');
                document.documentElement.style.setProperty('--dashboard-color-10-opacity', '#00C6FF10');
                document.documentElement.style.setProperty('--dashboard-color-20-opacity', '#00C6FF20');
            }
            if (user && user.dashboard_text_color) {
                document.documentElement.style.setProperty('--dashboard-text-color', user.dashboard_text_color);
            } else {
                document.documentElement.style.setProperty('--dashboard-text-color', 'white');
            }
        });

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
        this.adminSession = this.authService.userAdminSessionGet();
        if (this.authService.user.team_role === 1) {
            if (this.authService.user.admin_status === 11) {
                this.dialogHelper.open({
                    title: this.translate.instant('TEAMS_MEMBERS.ADMIN_ACCESS.QUESTION_POPUP.TITLE'),
                    description: this.translate.instant('TEAMS_MEMBERS.ADMIN_ACCESS.QUESTION_POPUP.DESC'),
                    width: '550px',
                    disableClose: true,
                }).then(async () => {
                    this.apiService.request({
                        method: 'put',
                        btnIm: true,
                        url: '/teams/subaccount/status/',
                        sessionHash: true,
                        params: {
                            adminStatus: 12,
                        }
                    }).subscribe(() => {
                        this.authService.user.adminStatus = 12;
                    }, (error) => {
                        console.error(error);
                    });
                }, () => {
                    this.apiService.request({
                        method: 'put',
                        btnIm: true,
                        url: '/teams/subaccount/status/',
                        sessionHash: true,
                        params: {
                            adminStatus: 13,
                        }
                    }).subscribe(() => {
                        this.authService.user.adminStatus = 13;
                    }, (error) => {
                        console.error(error);
                    });
                });
            }
        }
    }

    backToAdmin() {
        this.authService.userSessionSet(this.adminSession);
        this.authService.userAdminSessionSet(false);
        this.router.navigate([this.localize.translateRoute('/expert/team_members/')]).then(() => {
            window.location.reload();
        });
    }

    getDataKeyValue(child, key) {
        if (typeof child.data[key] !== 'undefined') {
            return child.data[key];
        } else if (child.children.length > 0) {
            return this.getDataKeyValue(child.children[0], key);
        }
        return;
    }

    eventClose($event) {
        this.eventService.closed($event);
    }

    ngOnDestroy() {
        (<any>window).Intercom('shutdown');
    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }
}
