import { Component, OnInit, Inject } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../shared/auth.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { AppointmentsService } from './dashboard/appointments/service';
import { WhiteLabelService } from '../shared/white-label.service';
import { UpgradeService } from '../shared/upgrade.service';
import { OnboardingService } from '../shared/onboarding.service';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
})
export class ExpertComponent extends OnDestroyMixin implements OnInit  {
    user: any = false;
    isFullyRegistered = false;
    currentLanguage = '';
    avatar = '';
    showNavigation = false;
    hideWarningPayment = false;
    hideWarningSubscription = false;
    isPhone = false;
    isNavigationCollapsed = false;

    constructor(
        private authService: AuthService,
        private localize: LocalizeRouterService,
        private translate: TranslateService,
        private router: Router,
        public upgradeService: UpgradeService,
        private onboardingService: OnboardingService,
        private appointmentsService: AppointmentsService,
        private whiteLabelService: WhiteLabelService,
        @Inject(DOCUMENT) document) {
        super();

        this.isPhone = window.outerWidth > 0 && window.outerWidth <= 1024;
        if (!this.isPhone) {
            this.showNavigation = true;
        }

        router.events.pipe(takeUntil(componentDestroyed(this))).subscribe((event) => {
            // New page loading
            if (event instanceof NavigationEnd) {
                document.querySelector('#mainContent').scrollTo(0, 0);
                if (this.isPhone) {
                    this.showNavigation = false;
                }
            }
        });
        // ToDo: also hide menu on mobile when clicked same menu item


        this.user = authService.user;
        if (this.user) {
            this.isFullyRegistered = this.user['active'] > 0 && this.user['email_step'] > 3;
            this.onboardingService.initializer();
        }
        this.upgradeService.initializer();
        const currentLang = this.translate.currentLang === 'en' ? '' : this.translate.currentLang;
        this.currentLanguage = currentLang;
        authService.loginStatusChange.pipe(takeUntil(componentDestroyed(this))).subscribe((user: any) => {
            this.user = user;
            if (this.user) {
                this.isFullyRegistered = this.user['active'] > 0 && this.user['email_step'] > 3;
                this.onboardingService.initializer();
            }
            if (this.user === false) {
                this.router.navigate([this.localize.translateRoute('/auth/login')]);
            } else {
                if (this.user['avatar']) {
                    const timeStamp = (new Date()).getTime();
                    /* We get the avatar with the current timestamp as a param.
                        The point of the timestamp is to force the browser to query the Backend again to get the picture.
                        Without this, the browser would not query the Backend and would keep the potentially older version of the avatar */
                    this.avatar = user['avatar'] + '?' + timeStamp;
                }
                // this.router.navigate([this.localize.translateRoute('/')]);
            }
        });
        if (this.user['avatar']) {
            const timeStamp = (new Date()).getTime();
            /* We get the avatar with the current timestamp as a param. */
            this.avatar = this.user['avatar'] + '?' + timeStamp;
        }
        this.translate.onLangChange.pipe(takeUntil(componentDestroyed(this))).subscribe((event: LangChangeEvent) => {
            this.currentLanguage = this.translate.currentLang;
        });
    }

    async ngOnInit() {
        if (this.user) {
            if (this.user.team_role === 0 && this.user.admin_stripe_customer_id) {
                (window as any).profitwell('start', { 'user_id': this.user.admin_stripe_customer_id });
            }
            if (this.whiteLabelService.whiteLabelSettingsChecked) {
                if (!this.whiteLabelService.whiteLabelSettings ||
                    (this.whiteLabelService.whiteLabelSettings && this.whiteLabelService.whiteLabelSettings.hide_intercom !== 1)) {
                    this.giveUserToIntercom();
                }
            } else {
                const domain = document.location.hostname;
                if (domain !== environment.baseUrlWithoutHttp && environment.production) {
                    this.whiteLabelService.getWhiteLabelSettings(domain).then(
                        (response: any) => {
                            if (response.hide_intercom !== 1) {
                                this.giveUserToIntercom();
                            }
                        },
                        () => {
                            this.giveUserToIntercom();
                        }
                    );
                } else {
                    this.giveUserToIntercom();
                }
            }
            // Google Analytics user identification
            /* Try/catch to avoid crashes because of tracking */
            try {
                // (window as any).gtag('set', {'user_id': this.user.id});
            } catch (err) {
                console.error(err);
            }
        }
    }

    giveUserToIntercom() {
        const userCreatedDate = new Date(this.user.created).getTime() / 1000;

        // Intercom user identification
        (<any>window).Intercom('shutdown');
        (<any>window).intercomSettings.name = this.user.first_name + ' ' + this.user.last_name;
        (<any>window).intercomSettings.user_hash = this.user.intercom_hmac;
        (<any>window).intercomSettings.created_at = userCreatedDate.toFixed(0);
        (<any>window).intercomSettings.RegistrationDate = userCreatedDate.toFixed(0);
        (<any>window).intercomSettings.email = this.user.email;
        (<any>window).intercomSettings.phone = this.user.phone;
        (<any>window).intercomSettings.slug = this.user.slug;
        (<any>window).intercomSettings.appLanguage = this.user.locale_code;
        (<any>window).intercomSettings.package = this.user.package_id;
        (<any>window).intercomSettings.emailStep = this.user.email_step;
        (<any>window).intercomSettings.useBilling = this.user.use_billing === 1 ? true : false;
        (<any>window).intercomSettings.calendarSynced = this.user.google_enabled === 1 ||
            this.user.microsoft_enabled === 1 || this.user.microsoft_exchange_enabled === 1 ? true : false;
        (<any>window).intercomSettings.nbAppointments = this.appointmentsService.pagination.past.total;
        (<any>window).intercomSettings.newsletter = this.user.newsletter === 1 ? true : false;
        (<any>window).intercomSettings.voucherId = this.user.voucher_id;
        if (this.user.voucher_id != null) {
            (<any>window).intercomSettings.voucher_active = this.user.voucher_active;
            (<any>window).intercomSettings.voucher_code = this.user.voucher_code;
            (<any>window).intercomSettings.voucher_description = this.user.voucher_description;
        }
        (<any>window).intercomSettings.companyName = this.user.company;
        (<any>window).intercomSettings.website = this.user.www;
        (<any>window).intercomSettings.bookingLink = `${environment.btnImAppBase}/${this.user.default_booking_locale}/e/${this.user.slug}`;
        (<any>window).Intercom('update');
        this.appointmentsService.getMeetings('past', false, true);
        this.appointmentsService.changed_past.pipe(takeUntil(componentDestroyed(this))).subscribe(() => {
            this.appointmentsService.pagination.past.disablePrevNext = false;
            (<any>window).intercomSettings.nbAppointments = this.appointmentsService.pagination.past.total;
            (<any>window).Intercom('update');
        });
    }

    redirectToPayment(accepted: boolean) {
        this.hideWarningPayment = true;
        if (accepted) {
            this.router.navigate([this.localize.translateRoute('/expert/payments/')]);
        }
    }

    redirectToSubscription(accepted: boolean) {
        this.hideWarningSubscription = true;
        if (accepted) {
            this.router.navigate([this.localize.translateRoute('/expert/subscription/')]);
        }
    }

    openNavigation() {
        // this is for mobile navigation only, but could be initiated by swipe on desktop which is disabled by condition
        if (this.isPhone) {
            this.showNavigation = !this.showNavigation;
            window.scrollTo(0, 0);
        }
    }
    collapseNavigation() {
        // collapsing/expanding desktop navigation
        this.isNavigationCollapsed = !this.isNavigationCollapsed;
    }
}
