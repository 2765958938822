import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

import { AuthGuard } from '../../shared/auth-guard.service';
import { SuperAdminGuard } from '../../shared/super-admin-guard.service';
import { ExpertComponent } from '../component';

const expertRoutes: Routes = [
    {
        path: 'expert',
        component: ExpertComponent,
        children: [
            {
                path: '',
                redirectTo: 'appointments',
                pathMatch: 'full',
            },
            {
                path: 'appointments',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./appointments/module').then(m => m.AppointmentsModule),
            },
            {
                path: 'self_schedule',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./self_schedule/module').then(m => m.SelfScheduleModule),
            },
            {
                path: 'clients',
                redirectTo: 'contacts/all',
                // ToDo: remove after some time when 'clients' link is forgotten by users
            },
            {
                path: 'contacts',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                children: [
                    {
                        path: 'all',
                        pathMatch: 'full',
                        loadChildren: () => import('./contacts/module').then(m => m.ContactsModule),
                    },
                    {
                        path: ':email',
                        pathMatch: 'prefix',
                        loadChildren: () => import('./contact-details/module').then(m => m.ContactDetailsModule),
                    },
                ],
            },
            {
                path: 'offer',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                children: [
                    {
                        path: 'all',
                        pathMatch: 'full',
                        loadChildren: () => import('./my-offer/module').then(m => m.OfferModule),
                    },
                    {
                        path: 'personal',
                        pathMatch: 'prefix',
                        children: [
                            {
                                path: ':id',
                                pathMatch: 'prefix',
                                data: {meeting_type: 'personal'},
                                loadChildren: () => import('./my-offer/service/module').then(m => m.ServiceModule),
                            },
                        ]
                    },
                    {
                        path: 'round_robin',
                        pathMatch: 'prefix',
                        children: [
                            {
                                path: ':id',
                                pathMatch: 'prefix',
                                data: {meeting_type: 'round_robin'},
                                loadChildren: () => import('./my-offer/service/module').then(m => m.ServiceModule),
                            },
                        ]
                    },
                    {
                        path: 'collective',
                        pathMatch: 'prefix',
                        children: [
                            {
                                path: ':id',
                                pathMatch: 'prefix',
                                data: {meeting_type: 'collective'},
                                loadChildren: () => import('./my-offer/service/module').then(m => m.ServiceModule),
                            },
                        ]
                    },
                ],
            },
            {
                path: 'forms',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./booking-forms/module').then(m => m.BookingFormsModule),
            },
            {
                path: 'emails',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./email/module').then(m => m.EmailModule),
            },
            {
                path: 'rooms',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./rooms/module').then(m => m.RoomsModule),
            },
            {
                path: 'availability',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./availability/module').then(m => m.AvailabilityModule),
            },
            {
                path: 'calendar_connections',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./calendar-connections/module').then(m => m.CalendarConnectionsModule),
            },
            {
                path: 'integrations',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        loadChildren: () => import('./integrations/module').then(m => m.IntegrationsModule),
                    },
                    {
                        path: 'zoom',
                        pathMatch: 'prefix',
                        loadChildren: () => import('./integrations/zoom/module').then(m => m.ZoomModule),
                    },
                ],
            },
            {
                path: 'profile',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./profile/module').then(m => m.ProfileModule),
            },
            {
                path: 'payments',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./payments/module').then(m => m.PaymentsModule),
            },
            {
                path: 'home',
                redirectTo: 'appointments',
                // ToDo: remove after some time when 'home' link is forgotten by users
            },
            {
                path: 'subaccounts',
                redirectTo: 'team_members',
                // ToDo: remove after some time when 'subaccounts' link is forgotten by users
            },
            {
                path: 'team_members',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./team-members/module').then(m => m.TeamMembersModule),
            },
            {
                path: 'team_profile',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./team-profile/module').then(m => m.TeamProfileModule),
            },
            {
                path: 'team_page',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./customize-team-booking/module').then(m => m.CustomizeTeamBookingModule),
            },
            {
                path: 'subscription',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true},
                loadChildren: () => import('./subscription/module').then(m => m.SubscriptionModule),
            },
            {
                path: 'meetfox_management',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./meetfox_management/module').then(m => m.MeetFoxManagementModule),
            },
            /* {
                path: 'invite_friend',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./invite-friend/module').then(m => m.InviteFriendModule),
            }, */
            {
                path: 'customize_booking',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./customize-booking/module').then(m => m.CustomizeBookingModule),
            },
            {
                path: 'domain_setup',
                pathMatch: 'full',
                canActivate: [AuthGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./domain-setup/module').then(m => m.DomainSetupModule),
            },
            {
                path: 'users_data',
                pathMatch: 'full',
                canActivate: [AuthGuard, SuperAdminGuard],
                data: {loginStatusShouldBe: true, userShouldBeFullyRegistered: true, package: true},
                loadChildren: () => import('./user_data/module').then(m => m.UserDataModule),
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(expertRoutes),
        LocalizeRouterModule.forChild(<any> expertRoutes),
    ],
})
export class ExpertDashboardModule {
}

