
import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormiFrameDialogComponent } from '../../form/iframe/component';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { AppointmentsService } from '../dashboard/appointments/service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../../app/shared/api.service';
import { UpgradeService } from '../../shared/upgrade.service';
import { WhiteLabelService } from '../../shared/white-label.service';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-navigation-component',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
})

export class NavigationComponent extends OnDestroyMixin {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @Input() user: any = false;
    @Input() avatar = '';
    @Input() isPhone = false;
    @Output() closeMenu = new EventEmitter<string>();
    @Output() collapseMenu = new EventEmitter<boolean>();
    intercomChatLink = 'mailto:' + environment.supportEmail;
    customLogo = '';
    openedMenuFolder = '';
    waitingForLogo = true;
    isMenuCollapsed = false;
    hideHelp = false;
    showSettings = 0;
    constructor (
        public dialog: MatDialog,
        private translate: TranslateService,
        private router: Router,
        private authService: AuthService,
        private appointmentsService: AppointmentsService,
        private apiService: ApiService,
        public upgradeService: UpgradeService,
        private whiteLabelService: WhiteLabelService) {
            super();
            // open active submenu on the load
            this.openSubmenu();
            // open active submenu on route change
            router.events.pipe(takeUntil(componentDestroyed(this))).subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.openSubmenu();
                }
            });
            // load custom logo
            if (this.whiteLabelService.whiteLabelSettingsChecked) {
                if (this.whiteLabelService.whiteLabelSettings) {
                    if (this.whiteLabelService.whiteLabelSettings.logoWhiteLabelSetting) {
                        this.customLogo = this.whiteLabelService.whiteLabelSettings.logoWhiteLabelSetting;
                    }
                    this.hideHelp = this.whiteLabelService.whiteLabelSettings.hide_intercom !== 1 ? false : true;
                }
                this.waitingForLogo = false;
            } else {
                const domain = document.location.hostname;
                if (domain !== environment.baseUrlWithoutHttp && environment.production) {
                    this.whiteLabelService.getWhiteLabelSettings(domain).then(
                        (response: any) => {
                            if (response.logoWhiteLabelSetting) {
                                this.customLogo = response.logoWhiteLabelSetting;
                            }
                            if (response.hide_intercom === 1) {
                                this.hideHelp = true;
                            }
                            this.waitingForLogo = false;
                        },
                        () => {
                            this.waitingForLogo = false;
                        }
                    );
                } else {
                    this.waitingForLogo = false;
                }
            }
        }

    bookDemo() {
        const widthDialog = window.outerWidth > 767 ? '70%' : '100%';
        const heightDialog = window.outerWidth > 767 ? '90%' : '100%';
        this.dialog.open(FormiFrameDialogComponent, {
        width: widthDialog,
        height: heightDialog,
        maxWidth: '100%',
        maxHeight: '100%',
        disableClose: false,
        data: { url: 'https://meetfox.com/' + (this.translate.currentLang === 'de' ? 'de' : 'en') + '/e/meetfoxdemo/borderless/?w=1'},
        panelClass: 'custom-dialog-container',
        });
    }

    toggleMenuFolder(menuFolder) {
        if (this.openedMenuFolder === menuFolder) {
            // when clicking on same folder, close it
            this.openedMenuFolder = '';
        } else {
            // when clicking on different folder, open new one
            this.openedMenuFolder = menuFolder;
        }
    }

    toggleMenu(menuItem) {
        // always when menu item clicked on mobile, close menu
        if (this.isPhone) {
            this.closeMenu.emit();
        }

        // open/close menu folder for the future load, based on menu item clicked
        if (menuItem === 'set_offer' || menuItem === 'forms' || menuItem === 'emails') {
            this.openedMenuFolder = 'offer';
        } else if (menuItem === 'set_availability' || menuItem === 'calendar_connections') {
            this.openedMenuFolder = 'availability';
        } else if (menuItem === 'team_profile' || menuItem === 'team_members' || menuItem === 'team_page') {
            this.openedMenuFolder = 'teams';
        } else {
            this.toggleMenuFolder('');
        }
    }

    collapsingMenu() {
        this.collapseMenu.emit(this.isMenuCollapsed);
        this.isMenuCollapsed = !this.isMenuCollapsed;
    }

    logout() {
        this.authService.logout();
        this.appointmentsService.reset();
        (window as any).Intercom('shutdown');
        (window as any).intercomSettings.name = null;
        (window as any).intercomSettings.user_hash = null;
        (window as any).intercomSettings.created_at = null;
        (window as any).intercomSettings.RegistrationDate = null;
        (window as any).intercomSettings.email = null;
        (window as any).intercomSettings.phone = null;
        (window as any).intercomSettings.slug = null;
        (window as any).intercomSettings.appLanguage = null;
        (window as any).intercomSettings.package = null;
        (window as any).intercomSettings.emailStep = null;
        (window as any).intercomSettings.useBilling = null;
        (window as any).intercomSettings.calendarSynced = null;
        (window as any).intercomSettings.nbAppointments = null;
        (window as any).intercomSettings.newsletter = null;
        (window as any).intercomSettings.voucherId = null;
        (window as any).intercomSettings.voucher_active = null;
        (window as any).intercomSettings.voucher_code = null;
        (window as any).intercomSettings.voucher_description = null;
        (window as any).intercomSettings.companyName = null;
        (window as any).intercomSettings.website = null;
        (window as any).intercomSettings.bookingLink = null;
        (window as any).intercomSettings.GamificationPercent = null;
        (window as any).intercomSettings.GamificationDemoVideo = null;
        (window as any).intercomSettings.GamificationIntegrationWebsite = null;
        (window as any).Intercom('update');
    }
    shouldAccessInviteFriendPage() {
        const domain = document.location.hostname;
        if (environment.staging || !environment.production || domain !== environment.baseUrlWithoutHttp || this.user['team_role'] !== 0) {
            return false;
        }
        return true;
    }

    shouldSeePackageItem() {
        if (this.user.team_role !== 0) {
            return false;
        } else if (this.user.package_core) {
            return (this.user.package_core < 3 || this.user.package_core > 9);
        } else {
            return false;
        }
    }

    settingsHover() {
        this.showSettings += 1;
    }
    settingsNoHover() {
        setTimeout(() => this.showSettings -= 1, 250);
    }
    openSubmenu() {
        if (this.router.url.indexOf('/offer') > -1 || this.router.url.indexOf('/emails') > -1 || this.router.url.indexOf('/forms') > -1) {
            this.openedMenuFolder = 'offer';
        } else if (this.router.url.indexOf('/availability') > -1 || this.router.url.indexOf('/calendar_connections') > -1) {
            this.openedMenuFolder = 'availability';
        } else if (this.router.url.indexOf('/team_members') > -1 || this.router.url.indexOf('/team_profile') > -1
            || this.router.url.indexOf('/team_page') > -1) {
            this.openedMenuFolder = 'teams';
        }
    }
}

