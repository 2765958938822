import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    template: `
        <h3 class="text-center"
            [innerHTML]="data['title']"></h3>
        <p *ngIf="data['description']"
            [innerHTML]="data['description']"></p>
        <div class="modal-buttons">
            <app-form-button
                (o_click)="no()"
                [color]="'secondary'">
                {{data['no']}}
            </app-form-button>
            <app-form-button
                (o_click)="yes()">
                {{data['yes']}}
            </app-form-button>
        </div>
    `,
    styles: [`
        p {
            margin: 0 0 20px 0;
            text-align: center;
        }
        .modal-buttons {
          display: flex;
          justify-content: center;
        }
        .modal-buttons app-form-button {
          margin-right: 5px;
        }
        @media screen and (min-width:0\0) {
            span[flex], md-card-content, md-dialog-content {
                position:absolute;
                top:50%;
                left:50%;
                display: block;
                min-width: 80%;
                min-height: 80%;
            }
        }
    `]
})
export class DialogConfirmComponent {
    constructor(public dialogRef: MatDialogRef<DialogConfirmComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    no() {
        this.dialogRef.close(false);
    }

    yes() {
        this.dialogRef.close(true);
    }
}
