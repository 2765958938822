import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { WarningUpgradeComponent } from '../expert/dashboard/package/warning-upgrade/component';
import { RequestUpgradeComponent } from '../expert/dashboard/package/request-upgrade/component';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class UpgradeService {

    user = null;
    trial = false;
    feature = '';
    description = '';

    canUseRecording = false;
    canChangeBookingDomain = false;
    canChangeEmailSender = false;
    canChangeLogo = false;
    canChangeFavicon = false;
    canCustomizeEmails = false;
    canUseCalendars = false;
    canUse16RoomParticipants = false;
    canUseZapier = false;
    canUseSMS = false;
    canChangeTerms = false;
    canAddMeetingTypes = false;
    maxRooms = 0;
    // paywall for now only for admins as subaccounts cannot create collective meeting types
    canAddCollective = false;

    /* Users only on package_core = 1 or package_core = 10 can add as many team member slots as they want and remove them as well.
        They can then upgrade to Pro or Premium for all the team members they have. */
    isOnlyOnCore1Or10 = false;

    o_upgraded_source: Subject<any> = new Subject<any>();
    o_upgraded: Observable<any> = this.o_upgraded_source.asObservable();

    constructor(private dialog: MatDialog,
        private translate: TranslateService,
        private authService: AuthService) {
        }
    initializer() {
        this.calculateAttributes();
    }

    calculateAttributes() {
        this.user = this.authService.user;
        this.trial = this.user.package_core > 9;
        this.canUseRecording = this.user.package_core > 2 || this.user.package_recording > 0;
        this.canChangeBookingDomain = this.user.package_cname > 0 || (this.user.package_core > 2 && this.user.is_appsumo < 1) || this.user.is_appsumo > 1;
        this.canChangeEmailSender = this.user.package_cname > 0 || (this.user.package_core > 2 && this.user.is_appsumo < 1) || this.user.is_appsumo > 1;
        this.canChangeLogo = this.user.package_cname > 0 || this.user.package_core > 1;
        this.canChangeFavicon = this.user.package_cname > 0 || this.user.package_core > 1;
        this.canCustomizeEmails = this.user.package_cname > 0 || this.user.package_core > 1;
        this.canUseCalendars = this.user.package_core > 1;
        this.canUse16RoomParticipants = this.user.package_core > 2 && this.user.is_appsumo < 1;
        this.canUseZapier = this.user.package_core > 1;
        this.canUseSMS = this.user.package_core > 1;
        this.canChangeTerms = this.user.package_cname > 0 || (this.user.package_core > 2 && this.user.is_appsumo < 1) || this.user.is_appsumo > 1;
        this.canAddMeetingTypes = this.user.package_core > 1;
        // can be used by users with 16 participants limit
        this.canAddCollective = this.user.package_core > 2 && this.user.is_appsumo < 1;

        // max amount of rooms is following:
        // plan core 10 -> 1000
        // plan core 1 -> 0
        // plan core 2 -> 1
        // plan core 3-> 1000
        if (this.user.package_core > 2) {
            this.maxRooms = 1000;
        } else if (this.user.package_core === 2) {
            this.maxRooms = 1;
        }
        this.isOnlyOnCore1Or10 = (this.user.package_core === 1 || this.user.package_core === 10)
            && this.user.package_cname < 1 && this.user.package_group_call < 1
            && this.user.package_recording < 1 && this.user.is_appsumo < 1;
    }

    canUseFeature(feature) {
        this.feature = feature;
        switch (feature) {
            case 'recording':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_RECORDING');
                return this.canUseRecording;
            case 'booking_domain':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_BOOKING_DOMAIN');
                return this.canChangeBookingDomain;
            case 'logo':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_LOGO');
                return this.canChangeLogo;
            case 'favicon':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_FAVICON');
               return this.canChangeFavicon;
            case 'email':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_EMAIL');
               return this.canCustomizeEmails;
            case 'smtp':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_SMTP');
                return this.canChangeEmailSender;
            case 'calendars':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_CALENDAR_CONNECT');
                return this.canUseCalendars;
            case 'rooms':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_ROOMS');
                return false; // ToDo: for now always shows upgrade pop-up, relies on a fact that its only called in this scenario
            case 'room_participants':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_ROOM_PARTICIPANTS');
                return this.canUse16RoomParticipants;
            case 'zapier':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_ZAPIER');
                return this.canUseZapier;
            case 'sms':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_SMS');
                return this.canUseSMS;
            case 'terms':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_TERMS_CONDITIONS');
                return this.canChangeTerms;
            case 'meeting_types':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_ADD_MEETING_TYPES');
                return this.canAddMeetingTypes;
            case 'team_members':
                this.description = this.translate.instant('REGISTER.PACKAGES.WARNING_UPDATE.DESC_TEAM_MEMBERS');
                return false; // ToDo: for now always shows upgrade pop-up, relies on a fact that its only called in this scenario
            case 'collective':
                return true; // for now collective pop-up is never shown
            default:
                return false;
        }
    }

    showUpgradePopup(from, feature) {
        const hasFeature = this.canUseFeature(feature);
        if (!hasFeature || (this.trial && from === 'paid_tag')) {
            const widthDialog = window.outerWidth > 767 ? '500px' : '100%';
            const heightDialog = window.outerWidth > 767 ? 'auto' : '100%';
            const userTraits = {
                user_id: this.user.id,
                user_type: 'expert',
                package_core: this.user.package_core,
            };
            if (this.user.team_role === 0) {
                this.dialog.open(WarningUpgradeComponent, {
                    data: {
                        description: this.description,
                    },
                    width: widthDialog,
                    height: heightDialog,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    disableClose: false,
                    autoFocus: false,
                    panelClass: ['custom-dialog-container-popup', 'light-blue-background'],
                });
                try {
                    (window as any).analytics.identify(this.user.id, userTraits);
                    (window as any).analytics.track('Upgrade Warning Shown', {
                        feature: this.feature,
                        from: from,
                    });
                } catch (err) {
                    console.error(err);
                }
            } else {
                this.dialog.open(RequestUpgradeComponent, {
                    data: {
                        feature: this.feature,
                    },
                    width: widthDialog,
                    height: heightDialog,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    disableClose: false,
                    autoFocus: false,
                    panelClass: ['custom-dialog-container-popup', 'light-blue-background'],
                });
                try {
                    (window as any).analytics.identify(this.user.id, userTraits);
                    (window as any).analytics.track('Upgrade Request Shown', {
                        feature: this.feature,
                        from: from,
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        }
    }
}
