<div class="phone-navbar" *ngIf="user && isFullyRegistered && isPhone">
    <a (click)="openNavigation()" class="phone-navbar-item">
        <mat-icon class="hamburger-menu">menu</mat-icon>
    </a>
</div>

<div class="app-layout"
    [ngClass]="{'phone-layout' : isPhone, 'auth-layout' : !user || !isFullyRegistered}">
    <div *ngIf="user && isFullyRegistered"
        class="main-navigation p-0 m-0"
        (swipeleft)="openNavigation()"
        [ngClass]="{'navigation-hidden' : !showNavigation, 'phone-navigation' : isPhone, 'main-navigation-animation' : isPhone, 'collapsed-navigation' : isNavigationCollapsed}">
        <app-navigation-component 
            class=""
            [avatar]="avatar"
            [isPhone]="isPhone" 
            [user]="user"
            (closeMenu)="openNavigation()"
            (collapseMenu)="collapseNavigation()">
        </app-navigation-component>
    </div>
    <div class="main-content" id="mainContent"
        (swiperight)="openNavigation()"
        [ngClass]="{'navigation-hidden' : isPhone && showNavigation, 'main-content-animation' : isPhone}">
       
        <!-- Payment warning  -->
        <div class="warning"
            [ngClass]="{'warning-mobile' : isPhone}"
            *ngIf="isFullyRegistered && user.has_priced_services === 1 && user.use_billing === 0 && user.own_payments === 0 && !hideWarningPayment">
            <p class="m-0">
                <span>
                    {{'DASHBOARD.GLOBAL_WARNING.ACTIVATE_PAYMENT.TEXT' | translate}}
                </span>
                <button mat-button class="mat-tertiary warning-button" 
                    (click)="redirectToPayment(true)">
                    {{'DASHBOARD.GLOBAL_WARNING.ACTIVATE_PAYMENT.BTN_ACTIVATE' | translate}}
                </button>
            </p>
            <a class="warning-close" (click)="redirectToPayment(false)">
            </a>
        </div>

        <!-- Don't show this banner if the warning payment banner is already being shown -->
        <div *ngIf="isFullyRegistered && !(user.has_priced_services === 1 && user.use_billing === 0 && user.own_payments === 0 && !hideWarningPayment)
            && user.package_core > 9 && user.team_role === 0 && !hideWarningSubscription"
            class="warning"
            [ngClass]="{'warning-mobile' : isPhone}">
            <p class="m-0">
                <span
                    [innerHTML]="'DASHBOARD.GLOBAL_WARNING.UPGRADE_DURING_TRIAL.TEXT' | translate:{trialRemainingDays: user.trial_remaining_days}"
                ></span>
                <button mat-button class="mat-tertiary warning-button" 
                    (click)="redirectToSubscription(true)">
                    {{'REGISTER.PACKAGES.UPGRADE_PACKAGE' | translate}}
                </button>
            </p>
            <a class="warning-close" (click)="redirectToSubscription(false)">
            </a>
        </div>

        <!-- Don't show this banner if the warning payment banner is already being shown -->
        <div *ngIf="isFullyRegistered && !(user.has_priced_services === 1 && user.use_billing === 0 && user.own_payments === 0 && !hideWarningPayment)
            && user.package_core < 2 && user.team_role === 0 && !hideWarningSubscription"
            class="warning"
            [ngClass]="{'warning-mobile' : isPhone}">
            <p class="m-0">
                <span>
                    {{'DASHBOARD.GLOBAL_WARNING.UPGRADE_AFTER_TRIAL.TEXT_START' | translate}}
                    <ng-container *ngIf="user.package_core === 1">
                        {{'REGISTER.PACKAGES.CORE.TITLE_CORE_1' | translate}}
                    </ng-container>
                    {{'DASHBOARD.GLOBAL_WARNING.UPGRADE_AFTER_TRIAL.TEXT_END' | translate}}
                </span>
                <button mat-button class="mat-tertiary warning-button" 
                    (click)="redirectToSubscription(true)">
                    {{'REGISTER.PACKAGES.UPGRADE_PACKAGE' | translate}}
                </button>
            </p>
            <a class="warning-close" (click)="redirectToSubscription(false)">
            </a>
        </div>
        <!-- end of warnings  -->

        <div id="content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
