export const environment = {
    production: true,
    staging: true,
    baseUrl: 'https://app.webutton.com',
    baseUrlWithoutHttp: 'app.webutton.com',
    btnImApiBase: 'https://api.webutton.com',
    btnImAppBase: 'https://webutton.com',
    btnImAppBaseWithoutURL: 'webutton.com',
    callAppBase: 'https://webutton.com',
    callAppBaseWithoutURL: 'webutton.com',
    apiBase: 'https://accounts.webutton.com',
    CNAME_value: 'meetfox-test.lide.clearalias.com',
    url_prefix: 'https://',
    hashPublic: '/4HY"b=7Za88Jz2~\'M%>PPq],b.o|mm]Ud(Om4V<_,!sdUqhLXk?TBm2-+P;Ne$',
    userHashCookieName: 'dashboard_UH',
    userHashCallCookieName: 'call_UH',
    userAdminHashCookieName: 'dashboard_AH',
    hashPrivateCookieName: 'dashboard_HP',
    cookiePath: '/',
    cookieDomain: 'webutton.com',
    cookieSecure: true,
    reCaptcha: '6LfER5wUAAAAALZiq23-2Uharz--kF01jGw6_pFs',
    supportUser: 'cb2ade606fcbc8892f56bad37496d01b5f26ffc0f029fe8265bdffdf059fbcb1',
    intercomKey: 'OnNORNmHi_vZh_YdiUoxvdtMB3LXeiT9echHmK4i',
    intercomId: 'b0yqm767',
    stripePublishableKey: 'pk_test_JvbhvoA5LbeOG9CsdORXMKqZ00KvAsdazm',
    googleAnalyticsKey: 'G-Q7RFLRZ56G',
    googleTagManagerAuth: 'QLryTOoFGkMZoULsyAzcpA',
    googleTagManagerEnv: 'env-11',
    segmentKey: 'UB9g3QtJkxQtoU2zM8tHQHBDqwor61WG',
    brand: 'MeetFox',
    supportEmail: 'support@meetfox.com',
    emailSender: 'no-reply@meetfox.com',
    ipRegistryKey: 'fpinr0ulx5y9ri',
};
