import { NgModule } from '@angular/core';
import { FormModule } from '../../form/form.module';
import { FormButtonComponent } from './component';

@NgModule({
    imports: [
        FormModule,
    ],
    declarations: [
        FormButtonComponent,
    ],
    exports: [
        FormButtonComponent,
    ],
})
export class FormButtonModule {
}
